import { COUNTRIES } from "./countries";

const jobLevelMenu = [
  { text: 'Select', value: '' },
  { text: 'Entry Level', value: 'Entry Level' },
  { text: 'Team Lead', value: 'Team Lead' },
  { text: 'Manager/Senior Manager', value: 'Manager/Senior Manager' },
  { text: 'Director/Senior Director', value: 'Director/Senior Director' },
  { text: 'Vice President/Senior Vice President', value: 'Vice President/Senior Vice President' },
  { text: 'C-Level (Reports to CEO)', value: 'C-Level (Reports to CEO)' },
  { text: 'CEO (Reports to Board of Directors)', value: 'CEO (Reports to Board of Directors)' },
  { text: 'Board of Directors member', value: 'Board of Directors member' },
  { text: 'Other', value: 'Other' },
  { text: 'Prefer not to respond', value: 'Prefer not to respond' }
];

const yearsatJobLevel = [
  { text: 'Select', value: '' },
  { text: '1 year or less', value: '1 year or less' },
  { text: '2-4 years', value: '2-4 years' },
  { text: '5-10 years', value: '5-10 years' },
  { text: '11-15 years', value: '11-15 years' },
  { text: '16-20 years', value: '16-20 years' },
  { text: 'More than 20 years', value: 'More than 20 years' },
  { text: 'Prefer not to respond', value: 'Prefer not to respond' }
];

const yearsinLeadershipRole = [
  { text: 'Select', value: '' },
  { text: '0 years', value: '0 years' },
  { text: 'Less than 1 year', value: 'Less than 1 year' },
  { text: '1 to 5 years', value: '1 to 5 years' },
  { text: '6 to 9 years', value: '6 to 9 years' },
  { text: '10 to 20 years', value: '10 to 20 years' },
  { text: 'More than 20 years', value: 'More than 20 years' },
  { text: 'Prefer not to respond', value: 'Prefer not to respond' }
];

const directReports = [
  { text: 'Select', value: '' },
  { text: '0', value: '0' },
  { text: '1 to 5', value: '1 to 5' },
  { text: '6 to 10', value: '6 to 10' },
  { text: '11 to 25', value: '11 to 25' },
  { text: '26 to 50', value: '26 to 50' },
  { text: '51 to 100', value: '51 to 100' },
  { text: 'More than 101', value: 'More than 101' },
  { text: 'Prefer not to respond', value: 'Prefer not to respond' }
];

const jobFunction = [
  { text: 'Select', value: '' },
  { text: 'Board of Directors', value: 'Board of Directors' },
  { text: 'Chief Executive Officer (Corporate)', value: 'Chief Executive Officer (Corporate)' },
  { text: 'Chief Executive Officer (Division)', value: 'Chief Executive Officer (Division)' },
  { text: 'Finance', value: 'Finance' },
  { text: 'General Management', value: 'General Management' },
  { text: 'Human Resources', value: 'Human Resources' },
  { text: 'Legal', value: 'Legal' },
  { text: 'Marketing', value: 'Marketing' },
  { text: 'Research & Development', value: 'Research & Development' },
  { text: 'Risk & Compliance', value: 'Risk & Compliance' },
  { text: 'Sales', value: 'Sales' },
  { text: 'Strategy', value: 'Strategy' },
  { text: 'Supply Chain & Operations', value: 'Supply Chain & Operations' },
  { text: 'Technology', value: 'Technology' },
  { text: 'Other (Please specify)', value: 'Other (Please specify)' }
];

const jobSubFunction = (jobFunction: any) => {
  const options: any = {
    'Board of Directors': [
      { text: 'Select', value: '' },
      { text: 'Non-Executive Chair', value: 'Non-Executive Chair' },
      { text: 'Advisory Role', value: 'Advisory Role' },
      { text: 'Non-Executive Director', value: 'Non-Executive Director' },
      { text: 'Senior/Lead Director', value: 'Senior/Lead Director' },
      { text: 'Other (Please specify)', value: 'Other (Please specify)' }
    ],
    'Chief Executive Officer (Corporate)': [
      { text: 'Select', value: '' },
      { text: 'Group Chief Executive Officer', value: 'Group Chief Executive Officer' },
      { text: 'Group Executive Chair', value: 'Group Executive Chair' },
      { text: 'Other (Please specify)', value: 'Other (Please specify)' }
    ],
    'Chief Executive Officer (Division)': [
      { text: 'Select', value: '' },
      { text: 'Divisional Chief Executive Officer', value: 'Divisional Chief Executive Officer' },
      { text: 'Managing Director', value: 'Managing Director' },
      { text: 'Regional Chief Executive Officer', value: 'Regional Chief Executive Officer' },
      { text: 'Other (Please specify)', value: 'Other (Please specify)' }
    ],
    'Finance': [
      { text: 'Select', value: '' },
      { text: 'Audit', value: 'Audit' },
      { text: 'Controller / Chief Accounting Officer', value: 'Controller / Chief Accounting Officer' },
      { text: 'Corporate Development / Mergers & Acquisition', value: 'Corporate Development / Mergers & Acquisition' },
      { text: 'Deputy Chief Financial Officer', value: 'Deputy Chief Financial Officer' },
      { text: 'Divisional or Regional Chief Financial Officer', value: 'Divisional or Regional Chief Financial Officer' },
      { text: 'Financial Planning & Analysis', value: 'Financial Planning & Analysis' },
      { text: 'Investor Relations', value: 'Investor Relations' },
      { text: 'Risk', value: 'Risk' },
      { text: 'Statutory / Group Chief Financial Officer', value: 'Statutory / Group Chief Financial Officer' },
      { text: 'Tax', value: 'Tax' },
      { text: 'Treasury', value: 'Treasury' },
      { text: 'Other (Please specify)', value: 'Other (Please specify)' }
    ],
    'General Management': [
      { text: 'Select', value: '' },
      { text: 'Chief Operating Officer', value: 'Chief Operating Officer' },
      { text: 'Division Leader', value: 'Division Leader' },
      { text: 'General Operations', value: 'General Operations' },
      { text: 'Region Leader', value: 'Region Leader' },
      { text: 'Other (Please specify)', value: 'Other (Please specify)' }
    ],
    'Human Resources': [
      { text: 'Select', value: '' },
      { text: 'Chief Human Resources Officer', value: 'Chief Human Resources Officer' },
      { text: 'Compensation and Benefits', value: 'Compensation and Benefits' },
      { text: 'Employee/Labor Relations', value: 'Employee/Labor Relations' },
      { text: 'HR Operations/IT/Analytics', value: 'HR Operations/IT/Analytics' },
      { text: 'HRBP/HR Generalist', value: 'HRBP/HR Generalist' },
      { text: 'Learning and Development/Organizational Development/Organizational Effectiveness', value: 'Learning and Development/Organizational Development/Organizational Effectiveness' },
      { text: 'Talent Acquisition', value: 'Talent Acquisition' },
      { text: 'Talent Management', value: 'Talent Management' },
      { text: 'Other (Please specify)', value: 'Other (Please specify)' }
    ],
    'Legal': [
      { text: 'Select', value: '' },
      { text: 'Compliance', value: 'Compliance' },
      { text: 'Government Affairs', value: 'Government Affairs' },
      { text: 'Internal Audit', value: 'Internal Audit' },
      { text: 'Legal', value: 'Legal' },
      { text: 'Risk', value: 'Risk' },
      { text: 'Other (Please specify)', value: 'Other (Please specify)' }
    ],
    'Marketing': [
      { text: 'Select', value: '' },
      { text: 'Advertising', value: 'Advertising' },
      { text: 'Brand Management', value: 'Brand Management' },
      { text: 'Communications', value: 'Communications' },
      { text: 'Customer Management', value: 'Customer Management' },
      { text: 'Marketing', value: 'Marketing' },
      { text: 'Media', value: 'Media' },
      { text: 'Planning', value: 'Planning' },
      { text: 'Product Development/Innovation', value: 'Product Development/Innovation' },
      { text: 'Sales', value: 'Sales' },
      { text: 'Strategy', value: 'Strategy' },
      { text: 'Other (Please specify)', value: 'Other (Please specify)' }
    ],
    'Research & Development': [
      { text: 'Select', value: '' },
      { text: 'Corporate', value: 'Corporate' },
      { text: 'Engineering', value: 'Engineering' },
      { text: 'Life Sciences', value: 'Life Sciences' },
      { text: 'Research & Development', value: 'Research & Development' },
      { text: 'Other (Please specify)', value: 'Other (Please specify)' }
    ],
    'Risk & Compliance': [
      { text: 'Select', value: '' },
      { text: 'Compliance', value: 'Compliance' },
      { text: 'Government Affairs', value: 'Government Affairs' },
      { text: 'Internal Audit', value: 'Internal Audit' },
      { text: 'Risk', value: 'Risk' },
      { text: 'Other (Please specify)', value: 'Other (Please specify)' }
    ],
    'Sales': [
      { text: 'Select', value: '' },
      { text: 'Advertising', value: 'Advertising' },
      { text: 'Brand Management', value: 'Brand Management' },
      { text: 'Communications', value: 'Communications' },
      { text: 'Customer Management', value: 'Customer Management' },
      { text: 'Marketing', value: 'Marketing' },
      { text: 'Media', value: 'Media' },
      { text: 'Planning', value: 'Planning' },
      { text: 'Product Development/Innovation', value: 'Product Development/Innovation' },
      { text: 'Sales', value: 'Sales' },
      { text: 'Strategy', value: 'Strategy' },
      { text: 'Other (Please specify)', value: 'Other (Please specify)' }
    ],
    'Strategy': [
      { text: 'Select', value: '' },
      { text: 'Advertising', value: 'Advertising' },
      { text: 'Brand Management', value: 'Brand Management' },
      { text: 'Communications', value: 'Communications' },
      { text: 'Customer Management', value: 'Customer Management' },
      { text: 'Marketing', value: 'Marketing' },
      { text: 'Media', value: 'Media' },
      { text: 'Planning', value: 'Planning' },
      { text: 'Product Development/Innovation', value: 'Product Development/Innovation' },
      { text: 'Sales', value: 'Sales' },
      { text: 'Strategy', value: 'Strategy' },
      { text: 'Other (Please specify)', value: 'Other (Please specify)' }
    ],
    'Supply Chain & Operations': [
      { text: 'Select', value: '' },
      { text: 'Distribution, Logistics and Transportation', value: 'Distribution, Logistics and Transportation' },
      { text: 'Innovation, R&D and Product Development', value: 'Innovation, R&D and Product Development' },
      { text: 'Manufacturing and Operations', value: 'Manufacturing and Operations' },
      { text: 'Procurement and Sourcing', value: 'Procurement and Sourcing' },
      { text: 'Supply Chain Planning', value: 'Supply Chain Planning' },
      { text: 'Infrastructure', value: 'Infrastructure' },
      { text: 'Product Development', value: 'Product Development' },
      { text: 'Security', value: 'Security' },
      { text: 'Services', value: 'Services' },
      { text: 'Strategy & Planning', value: 'Strategy & Planning' },
      { text: 'Systems', value: 'Systems' },
      { text: 'Chief Operating Officer', value: 'Chief Operating Officer' },
      { text: 'Division Leader', value: 'Division Leader' },
      { text: 'General Operations', value: 'General Operations' },
      { text: 'Region Leader', value: 'Region Leader' },
      { text: 'Other (Please specify)', value: 'Other (Please specify)' }
    ],
    'Technology': [
      { text: 'Select', value: '' },
      { text: 'Architecture', value: 'Architecture' },
      { text: 'Chief Information Officer', value: 'Chief Information Officer' },
      { text: 'Chief Product Officer', value: 'Chief Product Officer' },
      { text: 'Chief Technology Officer (Infrastructure)', value: 'Chief Technology Officer (Infrastructure)' },
      { text: 'Chief Technology Officer (Product)', value: 'Chief Technology Officer (Product)' },
      { text: 'Infrastructure', value: 'Infrastructure' },
      { text: 'Product Development', value: 'Product Development' },
      { text: 'Security', value: 'Security' },
      { text: 'Services', value: 'Services' },
      { text: 'Strategy & Planning', value: 'Strategy & Planning' },
      { text: 'Systems', value: 'Systems' },
      { text: 'Other (Please specify)', value: 'Other (Please specify)' }
    ]
  };
  return options[jobFunction];
};

const industryOptions = [
  { text: 'Select', value: '' },
  { text: 'Consumer Markets', value: 'Consumer Markets' },
  { text: 'Healthcare and Life Sciences', value: 'Healthcare and Life Sciences' },
  { text: 'Social Impact', value: 'Social Impact' },
  { text: 'Financial Services', value: 'Financial Services' },
  { text: 'Industrial', value: 'Industrial' },
  { text: 'Global Technology & Services', value: 'Global Technology & Services' },
  { text: 'Other (Please specify)', value: 'Other (Please specify)' },
  { text: 'Prefer not to respond', value: 'Prefer not to respond' }
];

const industrySectorOptions = (industry: any) => {
  const options: any = {
    'Consumer Markets': [
      { text: 'Select', value: '' },
      { text: 'Consumer Products', value: 'Consumer Products' },
      { text: 'Hospitality & Leisure', value: 'Hospitality & Leisure' },
      { text: 'Media & Entertainment', value: 'Media & Entertainment' },
      { text: 'Retail & Direct To Consumer', value: 'Retail & Direct To Consumer' },
      { text: 'Other (Please specify)', value: 'Other (Please specify)' }
    ],
    'Healthcare and Life Sciences': [
      { text: 'Select', value: '' },
      { text: 'Biopharmaceuticals', value: 'Biopharmaceuticals' },
      { text: 'Healthcare Services', value: 'Healthcare Services' },
      { text: 'Life Sciences Tools & Services', value: 'Life Sciences Tools & Services' },
      { text: 'Other (Please specify)', value: 'Other (Please specify)' }
    ],
    'Social Impact': [
      { text: 'Select', value: '' },
      { text: 'Academia Research & Education', value: 'Academia Research & Education' },
      { text: 'Associations', value: 'Associations' },
      { text: 'Foundations & Philanthropies', value: 'Foundations & Philanthropies' },
      { text: 'Government & Policy', value: 'Government & Policy' },
      { text: 'Non-profit & NGOs', value: 'Non-profit & NGOs' },
      { text: 'Other (Please specify)', value: 'Other (Please specify)' }
    ],
    'Financial Services': [
      { text: 'Select', value: '' },
      { text: 'Banking', value: 'Banking' },
      { text: 'Insurance', value: 'Insurance' },
      { text: 'Payments & Market Infrastructure', value: 'Payments & Market Infrastructure' },
      { text: 'Wealth & Asset Managements', value: 'Wealth & Asset Managements' },
      { text: 'Other (Please specify)', value: 'Other (Please specify)' }
    ],
    'Industrial': [
      { text: 'Select', value: '' },
      { text: 'Aerospace Defense & Aviation', value: 'Aerospace Defense & Aviation' },
      { text: 'Automotive', value: 'Automotive' },
      { text: 'Diversified Services', value: 'Diversified Services' },
      { text: 'Energy', value: 'Energy' },
      { text: 'Industrial Goods & Technology', value: 'Industrial Goods & Technology' },
      { text: 'Process Industries', value: 'Process Industries' },
      { text: 'Other (Please specify)', value: 'Other (Please specify)' }
    ],
    'Global Technology & Services': [
      { text: 'Select', value: '' },
      { text: 'Digital Infrastructure', value: 'Digital Infrastructure' },
      { text: 'Semi-Conductor', value: 'Semi-Conductor' },
      { text: 'Services', value: 'Services' },
      { text: 'Software', value: 'Software' },
      { text: 'Other (Please specify)', value: 'Other (Please specify)' }
    ]
  };

  return options[industry];
};

const orgType = [
  { text: 'Select', value: '' },
  { text: 'Public, for-profit', value: 'Public, for-profit' },
  { text: 'Privately held, for-profit', value: 'Privately held, for-profit' },
  { text: 'Non-profit', value: 'Non-profit' },
  { text: 'Employee-owned', value: 'Employee-owned' },
  { text: 'Government', value: 'Government' },
  { text: 'Other (Please specify)', value: 'Other (Please specify)' },
  { text: 'Prefer not to respond', value: 'Prefer not to respond' }
];

const orgStructure = [
  { text: 'Select', value: '' },
  { text: 'Functional (hierarchical, employees organized by specialization)', value: 'Functional (hierarchical, employees organized by specialization)' },
  { text: 'Divisional (hierarchical, employees organized by product, market, and/or region)', value: 'Divisional (hierarchical, employees organized by product, market, and/or region)' },
  { text: 'Matrixed (combines functional and divisional)', value: 'Matrixed (combines functional and divisional)' },
  { text: 'Boundary-less and/or flat (without traditional hierarchical or divisional boundaries, includes hollow, modular, network)', value: 'Boundary-less and/or flat (without traditional hierarchical or divisional boundaries, includes hollow, modular, network)' },
  { text: 'Team-Based (organized around self-managed teams or cross-functional)', value: 'Team-Based (organized around self-managed teams or cross-functional)' },
  { text: 'Other (Please specify)', value: 'Other (Please specify)' },
  { text: 'Prefer not to respond', value: 'Prefer not to respond' }
];

const orgSize = [
  { text: '1-50', value: '1-50' },
  { text: '51-100', value: '51-100' },
  { text: '101-500', value: '101-500' },
  { text: '501-1,000', value: '501-1,000' },
  { text: '1,001-3,000', value: '1,001-3,000' },
  { text: '3,001-5,000', value: '3,001-5,000' },
  { text: '5,001-10,000', value: '5,001-10,000' },
  { text: '10,001-25,000', value: '10,001-25,000' },
  { text: '25,001-50,000', value: '25,001-50,000' },
  { text: '50,001-100,000', value: '50,001-100,000' },
  { text: 'More than 100,000', value: 'More than 100,000' },
  { text: 'Prefer not to respond', value: 'Prefer not to respond' }
];

const orgCycle = [
  { text: 'Select', value: '' },
  { text: 'Startup/Seed (inconsistent growth rate, simple structure, and informal systems)', value: 'Startup/Seed (inconsistent growth rate, simple structure, and informal systems)' },
  { text: 'Growth (Significant expansion & revenue growth)', value: 'Growth (Significant expansion & revenue growth)' },
  { text: 'Expansion/Scale-up (solid market position, expanding to new markets, product offerings and/or diversifying revenue streams)', value: 'Expansion/Scale-up (solid market position, expanding to new markets, product offerings and/or diversifying revenue streams)' },
  { text: 'Maturity (slower growth, departmentalization, formalized systems)', value: 'Maturity (slower growth, departmentalization, formalized systems)' },
  { text: 'Turnaround Stage', value: 'Turnaround Stage' },
  { text: 'Decline/Repositioning (declining market demand or industry changes and repositioning by adapting)', value: 'Decline/Repositioning (declining market demand or industry changes and repositioning by adapting)' },
  { text: 'Other/Does not apply', value: 'Other/Does not apply' },
  { text: 'Prefer not to respond', value: 'Prefer not to respond' }
];

const revenue = [
  { text: 'Select', value: '' },
  { text: 'USD Less than 100M', value: 'USD Less than 100M' },
  { text: 'USD 100M-500M', value: 'USD 100M-500M' },
  { text: 'USD 500M-1B', value: 'USD 500M-1B' },
  { text: 'USD 1B-5B', value: 'USD 1B-5B' },
  { text: 'USD More than 5B', value: 'USD More than 5B' },
  { text: 'Prefer not to respond', value: 'Prefer not to respond' }
];

const workingEnvironment = [
  { text: 'Select', value: '' },
  { text: 'Office', value: 'Office' },
  { text: 'Hybrid', value: 'Hybrid' },
  { text: 'Remote', value: 'Remote' },
  { text: 'Prefer not to respond', value: 'Prefer not to respond' }
];

const europeCountires = [
  "Italy",
  "Germany",
  "France",
  "Netherlands",
  "Switzerland",
  "Greece",
  "Poland",
  "Ukraine",
  "Belgium",
  "Sweden",
  "Ireland",
  "Austria",
  "Croatia",
  "Norway",
  "Denmark",
  "Romania",
  "Luxembourg",
  "Finland",
  "Albania",
  "Iceland",
  "Malta",
  "Hungary",
  "Cyprus",
  "Bulgaria",
  "Estonia",
  "Slovenia",
  "Serbia",
  "Slovakia",
  "Lithuania",
  "Montenegro",
  "Andorra",
  "Liechtenstein",
  "Monaco",
  "Bosnia and Herzegovina",
  "Moldova",
  "North Macedonia",
  "Belarus",
  "Latvia",
  "San Marino",
  "United Arab Emirates",
];

const gender = (country: any) => {
  if (europeCountires.includes(country)) {
    return [
      { text: "Select", value: "" },
      { text: "Man", value: "Man" },
      { text: "Woman", value: "Woman" },
      { text: "Prefer not to respond", value: "Prefer not to respond" },
    ];
  } else {
    return [
      { text: "Select", value: "" },
      { text: "Man", value: "Man" },
      { text: "Woman", value: "Woman" },
      { text: "Other", value: "Other" },
      { text: "Prefer not to respond", value: "Prefer not to respond" },
    ];
  }
};

function ethnicity(country: string) {
  if (!country) {
    // disable select for ethnicity until United States of America, United Kingdom, CAN, or AUS
    return [
      { text: "Select", value: '' },
      { text: "Yes", value: "Yes" },
      { text: "No", value: "No" },
      { text: "Declined to Self-Identify", value: "Declined to Self-Identify" },
    ];
  }
  if (country === 'United States of America (the)') {
    return [
      { text: "Select", value: '' },
      { text: 'Black/African Descent', value: 'Black/African Descent' },
      { text: 'Asian Descent', value: 'Asian Descent' },
      { text: 'Pacific Islander/Hawaiian', value: 'Pacific Islander/Hawaiian' },
      { text: 'Hispanic/Latinx', value: 'Hispanic/Latinx' },
      { text: 'Indigenous', value: 'Indigenous' },
      { text: 'Other (Minority Ethnic)', value: 'Other (Minority Ethnic)' },
      { text: 'White/Caucasian', value: 'White/Caucasian' },
      { text: 'Declined to Self-Identify', value: 'Declined to Self-Identify' },
    ];
  } else if (country === 'United Kingdom of Great Britain and Northern Ireland (the)') {
    return [
      { text: "Select", value: '' },
      { text: 'Black/African Descent', value: 'Black/African Descent' },
      { text: 'Asian Descent', value: 'Asian Descent' },
      { text: 'Other (Minority Ethnic)', value: 'Other (Minority Ethnic)' },
      { text: 'White/Caucasian', value: 'White/Caucasian' },
      { text: 'Declined to Self-Identify', value: 'Declined to Self-Identify' },
    ];
  } else if (country === 'Canada') {
    return [
      { text: "Select", value: '' },
      { text: 'Black/African Descent', value: 'Black/African Descent' },
      { text: 'Asian Descent', value: 'Asian Descent' },
      { text: 'Indigenous', value: 'Indigenous' },
      { text: 'Other (Minority Ethnic)', value: 'Other (Minority Ethnic)' },
      { text: 'White/Caucasian', value: 'White/Caucasian' },
      { text: 'Declined to Self-Identify', value: 'Declined to Self-Identify' },
    ];
  } else if (country === 'Australia') {
    return [
      { text: "Select", value: '' },
      { text: 'Indigenous', value: 'Indigenous' },
      { text: 'Other (Minority Ethnic)', value: 'Other (Minority Ethnic)' },
      { text: 'White/Caucasian', value: 'White/Caucasian' },
      { text: 'Declined to Self-Identify', value: 'Declined to Self-Identify' },
    ];
  } else {
    // disable
    return [
      { text: "Select", value: '' },
      { text: "Yes", value: "Yes" },
      { text: "No", value: "No" },
      { text: "Declined to Self-Identify", value: "Declined to Self-Identify" },
    ];
  }
}

const lgbtqOptions = () => {
  return [
    { text: 'Select', value: '' },
    { text: 'Yes', value: 'Yes' },
    { text: 'No', value: 'No' },
    { text: 'Prefer not to respond', value: 'Prefer not to respond' }
  ];
}

const legalTipTool = [
  { text: 'Select', value: 'Select' },
  { text: 'Yes', value: 'Yes' },
  { text: 'No', value: 'No' },
  { text: 'Prefer not to respond', value: 'Prefer not to respond' }
];

const countryList = COUNTRIES.map((country) => { return { text: country.country_value, value: country.country_value }; });


const diversityMenu = () => {
  return [
    { text: 'Yes', value: 'Yes' },
    { text: 'No', value: 'No' },
    { text: 'Prefer not to respond', value: 'Prefer not to respond' }
  ];
}

const veteranStatus = () => {
  return [
    { text: 'Select', value: '' },
    { text: 'Yes - Disabled Veteran', value: 'Yes - Disabled Veteran' },
    { text: 'Yes - Recently Separated Veteran', value: 'Yes - Recently Separated Veteran' },
    { text: 'Yes - Active Duty Wartime or Campaign Badge Veteran', value: 'Yes - Active Duty Wartime or Campaign Badge Veteran' },
    { text: 'Yes - Armed Forces Service Medal Veteran', value: 'Yes - Armed Forces Service Medal Veteran' },
    { text: 'No', value: 'No' },
    { text: 'Prefer not to respond', value: 'Prefer not to respond' }
  ];
}

const education = [
  { text: 'Select', value: 'Select' },
  { text: 'No formal qualifications', value: 'No formal qualifications' },
  { text: 'High School/Secondary School', value: 'High School/Secondary School' },
  { text: 'Some University/Associate Degree', value: 'Some University/Associate Degree' },
  { text: 'Bachelor’s Degree', value: 'Bachelor’s Degree' },
  { text: 'Postgraduate - Master’s Degree', value: 'Postgraduate - Master’s Degree' },
  { text: 'Postgraduate - PhD', value: 'Postgraduate - PhD' },
  { text: 'Other (Please specify)', value: 'Other (Please specify)' },
  { text: 'Prefer not to respond', value: 'Prefer not to respond' }
];

export default {
  jobLevelMenu,
  yearsatJobLevel,
  yearsinLeadershipRole,
  directReports,
  jobFunction,
  jobSubFunction,
  industryOptions,
  industrySectorOptions,
  orgType,
  orgStructure,
  orgSize,
  orgCycle,
  revenue,
  workingEnvironment,
  gender,
  ethnicity,
  lgbtqOptions,
  diversityMenu,
  veteranStatus,
  education,
  countryList,
  legalTipTool
};