import React from 'react';

export const CopilotIconLargeSolidColor = (): JSX.Element => {
  return (
    <svg
      className={'copilot-icon-large-solid-color'}
      fill="none"
      height="24"
      width="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.9854 19.5924L21.4214 12.3832C21.026 7.35599 18.9045 4.90175 17.1946 3.72861L17.1418 3.69262C13.2739 1.08484 8.09614 1.53047 4.83232 4.75241L4.68652 4.90355C1.5247 8.16568 1.10472 13.3135 3.68758 17.143L3.80757 17.2581V17.3157C5.00151 18.9867 7.45417 21.0343 12.3811 21.4217L19.4667 21.9759V21.9741L19.6593 21.9855C19.8147 22.0011 19.9359 22.0035 20.0493 21.9957C20.5515 21.9567 21.0248 21.7384 21.38 21.3821C21.7376 21.0253 21.9566 20.552 21.995 20.05C22.004 19.9385 22.001 19.8167 21.9854 19.6656V19.5924ZM12.7117 17.1994C10.0826 16.9924 8.12913 16.1372 7.21059 14.7901C5.71367 12.5866 5.94346 9.6531 7.75716 7.81422L7.80455 7.76623C9.64585 5.94834 12.5827 5.71623 14.787 7.21325C16.1351 8.13209 16.9912 10.0855 17.1982 12.7137L17.5786 17.579L12.7117 17.1994Z"
        fill="#1A212E"
      />
    </svg>
  );
};
