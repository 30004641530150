import React, { useContext, useEffect, useState } from "react";
import AuthHeader from '../commons/AuthHeader';
import AuthFooter from '../commons/AuthFooter';
import { Row, Col, Pill, PillIconAlign, PillSize, PillType, Button, ButtonVariant, Stepper, StepperVariant, StepperLineStyle, StepperSize, StepSize } from "@eightfold.ai/octuple";
import { actionButtonStyle } from "../commons/commonStyles";
import { globalContext } from "../store";
import Api from "../Api";

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import ReportCard from "../Components/ReportCard";
import Loader from "../commons/Loader";
dayjs.extend(utc);
dayjs.extend(timezone);

const formatDate = (dateString: any) => {
  const formatedDate = dayjs(dateString).format('MMM D, YYYY');
  return formatedDate
}

const getDaysDifferenceTodaysDate = (dueDate: any, todaysDate: any) => {
  if (todaysDate.getTime() === dueDate.getTime()) {
    return 0;
  }
  const msDiff = dueDate.getTime() - todaysDate.getTime();
  let days = Math.floor(msDiff / (1000 * 60 * 60 * 24));
  return Math.abs(days);
}

const GeneratePill = (props: any) => {
  const assessmentsData = props?.assessmentsData;
  let pillLabel = 'New';
  let pillClass = 'pillStyleBlue';
  let todaysDate = new Date();
  const stripTime = (date: any) => new Date(date.getFullYear(), date.getMonth(), date.getDate());

  const dueDate = new Date(assessmentsData.dueDate);
  const strippedDueDate = stripTime(dueDate);
  const strippedTodaysDate = stripTime(todaysDate);

  const days = getDaysDifferenceTodaysDate(dueDate, strippedTodaysDate)

  if (strippedDueDate.getTime() < strippedTodaysDate.getTime()) {
    pillLabel = (days === 1) ? `Overdue by ${days} Day` : `Overdue by ${days} Days`;
    pillClass = 'pillStyleRed';
  }

  if (strippedDueDate.getTime() === strippedTodaysDate.getTime()) {
    pillLabel = `Due today`;
    pillClass = 'pillStyleRed';
  }

  if (strippedDueDate.getTime() > new Date(todaysDate.setDate(todaysDate.getDate())).getTime() && strippedDueDate.getTime() < new Date(todaysDate.setDate(todaysDate.getDate() + 5)).getTime()) {
    pillLabel = (days === 1) ? `Due in ${days} Day` : `Due in ${days} Days`;
    pillClass = 'pillStyleRed';
  }

  todaysDate = new Date();
  if (strippedDueDate.getTime() > new Date(todaysDate.setDate(todaysDate.getDate() + 5)).getTime() && assessmentsData.status === 'I') {
    pillLabel = (days === 1) ? `Due in ${days} Day` : `Due in ${days} Days`;
    pillClass = 'pillStyleBlue';
  }

  return (
    <Pill
      alignIcon={PillIconAlign.Start}
      closeButtonProps={{
        ariaLabel: 'Close'
      }}
      configContextProps={{
        noDisabledContext: false,
        noGradientContext: false,
        noSizeContext: false
      }}
      label={pillLabel}
      lineClamp={0}
      onClick={function noRefCheck() { }}
      onClose={function noRefCheck() { }}
      classNames={pillClass}
      size={PillSize.Small}
      type={PillType.default}
    />
  )
};

const GenerateCards = (props: any) => {
  const assessmentsData: any = props?.assessmentsData;
  const isMobile: any = props?.isMobile;
  const assessmentSourceStatus = assessmentsData?.source?.status;
  const assessmentDataStatus = assessmentsData?.status;
  const stepperClass = (assessmentSourceStatus || assessmentDataStatus);
  return (
    <Row classNames="gridLeftRow">
      {/* flex={(isMobile) ? "auto" : "0 1 200px"} */}
      {/* flex={(isMobile) ? "auto" : "1 1 200px"} */}
      <Col classNames="gridLeftRowsLeftColumnDetails">
        {assessmentSourceStatus !== 'C' && <GeneratePill assessmentsData={assessmentsData} />}
        <h3>{assessmentsData?.title}</h3>
        {assessmentsData?.status !== 'C' &&
          <Button
            text="Begin"
            variant={ButtonVariant.Secondary}
            classNames="actionButton"
            style={actionButtonStyle}
          />
        }
      </Col>
      <Col classNames="gridLeftRowsRightColumnDetails">
        <p className="paragraphStyle" style={{ marginTop: '0px' }}>{'Experiences and results that demonstrate your success in achieving business objectives.'}</p>
        <div className={`stepper-container ${stepperClass}`} style={{ width: '100%' }}>
          <Stepper
            activeStepIndex={(assessmentSourceStatus === 'N' || assessmentsData.assignmentStatus === 'NI') ? 0 : assessmentSourceStatus === 'I' ? 1 : 2}
            classNames={`myTimeline ${stepperClass}`}
            configContextProps={{
              noGradientContext: false,
              noThemeContext: false
            }}
            data-test-id="myTimelineTestId"
            id="myTimelineId"
            index={(assessmentSourceStatus === 'N' || assessmentDataStatus === 'NI') ? 0 : (assessmentSourceStatus === 'I') ? 1 : 2}
            layout={isMobile ? 'vertical' : 'horizontal'}
            lineStyle={StepperLineStyle.Solid}
            size={StepperSize.Medium}
            showActiveStepIndex
            steps={[
              {
                complete: (assessmentSourceStatus === 'N' || assessmentDataStatus === 'NI' || assessmentSourceStatus === 'I' || assessmentSourceStatus === 'C'),
                content: 'Assigned',
                index: 1,
                size: StepSize.Small,
              },
              {
                complete: (assessmentSourceStatus === 'N' || assessmentDataStatus === 'NI' || assessmentSourceStatus === 'I'),
                content: 'In Progress',
                index: 2,
                size: StepSize.Small,
              },
              {
                complete: (assessmentSourceStatus === 'C'),
                content: 'Completed',
                index: 3,
                size: StepSize.Small
              }
            ]}
            theme="grey"
            themeContainerId="my-timeline-theme-container"
            variant={StepperVariant.Timeline}
          />
        </div>
        <p className="dateStyle">
          <span>
            {assessmentSourceStatus === 'C' ?
              `Completed: ${formatDate(assessmentsData?.completedDate)}`
              :
              `Due: ${formatDate(assessmentsData?.dueDate)}`
            }
          </span>
          <span>
            Assigned: {formatDate(assessmentsData?.addedTs)}
          </span>
        </p>
      </Col>
    </Row>
  );
};


const DashBoard = () => {
  const { globalState } = useContext(globalContext);
  const title = globalState?.clientData?.name;

  const [reportsData, setReportsData] = useState<any>([]);
  const [showHideCompletedAssessments, setShowHideCompletedAssessmenst] = useState(false);
  const [assignedAssessments, setAssignedAssessments] = useState<any>([]);
  const [completedAssessArr, setCompletedAssessArr] = useState<any>([]);
  const [showLoader, setShowLoader] = useState(true);
  const [assessmentStructureDetails, setAssessStructureDetails] = useState<any>([]);
  const [assessmentDetails, setAssessmentDetails] = useState<any>([])
  const [leaderName, setLeaderName] = useState<any>('')
  const showHideCompletedAssess = () => {
    setShowHideCompletedAssessmenst(!showHideCompletedAssessments)
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth < 450);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {
    if (Object.keys(globalState?.leaderData).length === 0 || Object.keys(globalState?.lookUpData).length === 0) {
      getLeaderData();
    } else {
      setLeaderName(globalState?.leaderData?.firstName)
      getLeaderProjects(globalState?.leaderData?.id);
    }
  }, []);

  const getLeaderData = () => {
    let loginToken: any = JSON.parse(
      sessionStorage.getItem("loginToken") ?? "false"
    );

    const payload = {
      email: loginToken?.idToken?.claims?.email.toLowerCase(),
      clientId: globalState?.clientData?.id,
    };

    Api.getLeaderProfile(payload).then((response) => {
      if (response?.success) {
        if (response && response?.response?.data && response?.response?.data?.data.length > 0) {
          const leaderProfileId = response?.response?.data?.data[0]?.id;
          setLeaderName(response?.response?.data?.data[0]?.firstName)
          getLeaderProjects(leaderProfileId);
          getLeaderReports(leaderProfileId);
        }
      }
    });
  }


  const getLeaderProjects = (leaderProfileId: any) => {
    let assessmentStructureIds: any = [];
    let idsString;
    Api.getLeaderProjects(globalState?.clientData?.id, leaderProfileId)
      .then((response: any) => {
        if (response?.success) {
          const flattenedAssignments = response?.response?.data?.data.flatMap((project: any) =>
            project.assignments.flatMap((assignment: any) =>
              assignment.assessments.map((assessment: any) => ({
                ...assessment,
                dueDate: project.closeDate || '',
                ...(assessment.assignmentStatus === 'Not Invited' && { status: 'NI' })
              }))
            )
          );

          // dont remove below 2 commented lines will need this after getting assessment data with link 
          //  const assessmentIds:any = flattenedAssignments.map((item:any) => item.assessmentId); 
          //  setAssessmentIds(assessmentIds)
          assessmentStructureIds.push(...flattenedAssignments.map((assessment: any) => assessment.assessmentStructureId));
          idsString = assessmentStructureIds.join(',');

          Api.getLeaderAssessmentStructureDetails(idsString)
            .then((res: any) => {
              setAssessStructureDetails(res?.response?.data?.data);
              getAssessmentStructureDetails(flattenedAssignments, res?.response?.data?.data);
              // dont remove below commented line will need this after getting assessment data with link 
              // getAssessmentDetails(flattenedAssignments,assessmentIds, res?.response?.data?.data);
            })
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  const getLeaderReports = (leaderProfileId: any) => {
    Api.getLeaderReports(leaderProfileId)
      .then((response: any) => {
        if (response?.success) {
          if (response && response?.response?.data?.data?.length > 0) {
            const res = response?.response?.data?.data
            const filteredData = res.filter((item: any) => item.isLeaderEnabled === true)
            filteredData.sort((a: any, b: any) => {
              const dateA = new Date(a.reportDate);
              const dateB = new Date(b.reportDate);

              switch (dateA > dateB) {
                case true:
                  return -1;
                case false:
                  return 1;
              }
              return a.name.localeCompare(b.name);
            });
            setReportsData(filteredData)
          }
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  const getAssessmentStructureDetails = async (flattenedAssignments: any, assessStructureData: any) => {
    const updatedData = flattenedAssignments.map((dataItem: any) => {
      let match: any;
      match = assessStructureData.find((structureItem: any) => structureItem.id === dataItem.assessmentStructureId);
      if (match) {
        return { ...dataItem, title: match.title };
      }
    });

    const finalUpdatedData = updatedData.map((item: any) => {
      if (item.closeDate !== '') {
        return { ...item, dueDate: item.dueDate };
      } else {
        const date = new Date(item.addedTs);
        date.setFullYear(date.getFullYear() + 1);
        const dueDate = date.toISOString();
        return { ...item, dueDate: dueDate };
      }
    })

    finalUpdatedData.sort((a: any, b: any) => {
      const dateA = new Date(a.dueDate);
      const dateB = new Date(b.dueDate);
      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;
      return a.title.localeCompare(b.title);
    });
    setAssignedAssessments(finalUpdatedData);
    setShowLoader(false);
  }

  const getAssessmentDetails = async (flattenedAssignments: any, assessmentIds: any, assessStructureData: any) => {
    await Api.getLeaderAssessmentDetails(globalState?.clientData?.id, globalState?.leaderData?.leaderProfileId)
      .then((res: any) => {
        const filteredData = res?.response?.data?.data?.filter((item: any) => assessmentIds.includes(item.id));
        const updatedData = filteredData.map((dataItem: any) => {
          let match: any;
          if (dataItem?.source?.status === 'C') {
            match = assessStructureData.find((structureItem: any) => structureItem.sourceAssessmentId === dataItem.source.assessmentID);
          } else {
            match = assessStructureData.find((structureItem: any) => structureItem.id === dataItem.source.assessmentID);
          }
          if (match) {
            return { ...dataItem, title: match.title };
          }
          return dataItem;
        });

        const finalAssessDetails = updatedData.map((dataItem: any) => {
          const matchData = flattenedAssignments.find((object: any) => object.assessmentId == dataItem.id);
          if (matchData.dueDate != '') {
            return { ...dataItem, dueDate: matchData.dueDate };
          } else {
            const date = new Date(matchData.addedTs);
            date.setFullYear(date.getFullYear() + 1);
            const dueDate = date.toISOString();
            return { ...dataItem, dueDate: dueDate };
          }
        });

        setAssessmentDetails(finalAssessDetails)
        if (finalAssessDetails && finalAssessDetails.length > 0) {
          const filteredData = finalAssessDetails.filter((item: any) => item.source.status === 'C');
          const assignedAssessData = finalAssessDetails.filter((item: any) => item.source.status === 'N' || item.status === 'I');
          filteredData.sort((a: any, b: any) => {
            const dateA = new Date(a.dueDate);
            const dateB = new Date(b.dueDate);

            if (dateA > dateB) return -1;
            if (dateA < dateB) return 1;

            return a.title.localeCompare(b.title);
          });
          setCompletedAssessArr(filteredData);

          assignedAssessData.sort((a: any, b: any) => {
            const dateA = new Date(a.dueDate);
            const dateB = new Date(b.dueDate);
            if (dateA < dateB) return -1;
            if (dateA > dateB) return 1;
            return a.title.localeCompare(b.title);
          });
          setAssignedAssessments(assignedAssessData);
        }
      })
  }

  return (
    <>
      {showLoader && <Loader />}
      <div className="mainWrapper">
        <div className="dashboardPage">
          <AuthHeader title={title} setLoader={setShowLoader} />
          <div className="authHeaderBanner">
            <div className="authHeaderBannerContent">
              <p className="authHeaderBannerWelcomeContent">Welcome,</p>
              <h2 className="header2Style">{leaderName}</h2>
            </div>
          </div>
          <div className="middleWrapper">
            <Row classNames="gridWrapper">
              <Col classNames="gridLeftColumn">
                <h2>Assessments</h2>
                <>
                  {(assignedAssessments.length) > 0 ?
                    assignedAssessments.map((item: any) => (
                      <GenerateCards key={item?.id} assessmentsData={item} isMobile={isMobile} />
                    ))
                    :
                    <Row classNames="emptyDataState">
                      <Col classNames="emptyStateLogo">
                        <img className="classEllipse" src={`./../assets/img/Ellipse3Assessment.svg`} alt="" />
                        <img className="classGroup1" src={`./../assets/img/Group1.svg`} alt="" />
                        <img className="classGroup2" src={`./../assets/img/Group2.svg`} alt="" />
                        <h3>You don't have any assessments assigned yet</h3>
                      </Col>
                    </Row>
                  }

                  {completedAssessArr.length > 0 &&
                    <>
                      <div className="completedAssessList">
                        <img
                          className={showHideCompletedAssessments ? "showCompletedButtonCls" : ''}
                          alt="plus minus icon"
                          src={showHideCompletedAssessments ? `./../assets/img/HideCompletedAssess.png` : `./../assets/img/ShowCompletedAssess.png`}
                        />
                        <Button
                          text={showHideCompletedAssessments ? "Hide Completed Assessments" : "Show Completed Assessments"}
                          variant={ButtonVariant.SystemUI}
                          classNames="backButton"
                          onClick={showHideCompletedAssess}
                          transparent={true}
                        />
                      </div>

                      {showHideCompletedAssessments ?
                        <div className="showCompleted">
                          {completedAssessArr.map((item: any) => (
                            <GenerateCards key={item?.id} assessmentsData={item} isMobile={isMobile} />
                          ))}

                        </div> :
                        <></>
                      }
                    </>
                  }
                </>
              </Col>
              <ReportCard reportsData={reportsData} formatDate={formatDate} />
            </Row>
          </div>
          <AuthFooter />
        </div>
      </div>
    </>
  );
};

export default DashBoard;
