import React from 'react';

export const CopilotIconSmallSolidColor = (): JSX.Element => {
  return (
    <svg
      className={'copilot-icon-small-solid-color'}
      fill="none"
      height="16"
      width="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9912 12.5555L13.6528 8.22994C13.4156 5.2136 12.1427 3.74105 11.1168 3.03717L11.0851 3.01557C8.76431 1.45091 5.65768 1.71828 3.69939 3.65145L3.61191 3.74213C1.71482 5.69941 1.46283 8.78808 3.01255 11.0858L3.08454 11.1549V11.1894C3.8009 12.192 5.2725 13.4206 8.22866 13.653L12.48 13.9855V13.9845L12.5956 13.9913C12.6888 14.0006 12.7615 14.0021 12.8296 13.9974C13.1309 13.974 13.4149 13.843 13.628 13.6293C13.8426 13.4152 13.9739 13.1312 13.997 12.83C14.0024 12.7631 14.0006 12.69 13.9912 12.5994V12.5555ZM8.42701 11.1196C6.84958 10.9955 5.67748 10.4823 5.12635 9.67406C4.2282 8.35193 4.36607 6.59186 5.45429 5.48853L5.48273 5.45974C6.58751 4.36901 8.34961 4.22974 9.67218 5.12795C10.4811 5.67926 10.9947 6.85132 11.1189 8.42822L11.3472 11.3474L8.42701 11.1196Z"
        fill="#1A212E"
      />
    </svg>
  );
};
