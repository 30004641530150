import React from 'react';

export const CopilotIconMediumSolidColor = (): JSX.Element => {
  return (
    <svg
      className={'copilot-icon-medium-solid-color'}
      fill="none"
      height="20"
      width="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9883 16.0739L17.5371 10.3066C17.2208 6.28479 15.5236 4.3214 14.1557 3.38289L14.1135 3.3541C11.0191 1.26787 6.87691 1.62437 4.26585 4.20193L4.14922 4.32284C1.61976 6.93254 1.28378 11.0508 3.35006 14.1144L3.44606 14.2065V14.2526C4.40121 15.5893 6.36334 17.2274 10.3049 17.5374L15.9734 17.9807V17.9793L16.1274 17.9884C16.2518 18.0009 16.3487 18.0028 16.4394 17.9965C16.8412 17.9654 17.2199 17.7907 17.504 17.5057C17.7901 17.2202 17.9653 16.8416 17.996 16.44C18.0032 16.3508 18.0008 16.2534 17.9883 16.1325V16.0739ZM10.5693 14.1595C8.4661 13.9939 6.90331 13.3097 6.16847 12.2321C4.97093 10.4692 5.15476 8.12248 6.60572 6.65137L6.64364 6.61299C8.11668 5.15868 10.4662 4.97299 12.2296 6.1706C13.3081 6.90567 13.993 8.46842 14.1586 10.571L14.4629 14.4632L10.5693 14.1595Z"
        fill="#1A212E"
      />
    </svg>
  );
};
