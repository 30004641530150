import React from 'react';
import styles from '../empty.module.scss';

export const DefaultEmptyPlanImg = (): JSX.Element => {
  return (
    <svg
      className={'empty-plan-image-default'}
      fill="none"
      height="187"
      width="200"
      viewBox="0 0 200 187"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={styles.primaryColor10}
        d="M39.0718 180.839C21.8078 174.861 3.66074 165.325 0.510726 153.175C-2.63929 141.025 9.18787 126.359 24.8068 118.637C40.5358 111.029 59.9069 110.449 71.9662 105.257C83.9156 99.9498 88.5731 89.9309 99.9189 80.9985C111.265 72.066 129.299 64.2199 140.372 67.9517C151.555 71.798 155.648 87.2069 164.716 99.6426C173.785 112.078 187.68 121.625 194.904 134.657C202.237 147.804 202.768 164.422 189.061 169.219C175.355 174.016 147.3 166.877 128.36 168.625C109.569 170.288 99.7624 180.823 86.4541 184.955C73.2954 189.004 56.4854 186.734 39.0718 180.839Z"
        fill="currentColor"
      />
      <path
        className={styles.accentColor20}
        d="M183 115.819V134H72V115.819L72.4336 111.923C73.0117 108.748 74.168 105.718 75.9023 102.832C77.9258 98.5034 80.6719 94.7517 84.1406 91.5772C88.4766 87.8255 93.9687 84.7953 100.617 82.4866C108.133 79.8893 117.094 78.5906 127.5 78.5906C143.977 78.5906 156.984 81.7651 166.523 88.1141C173.461 92.7315 178.375 98.792 181.266 106.295C182.422 109.758 183 112.933 183 115.819ZM155.25 32.7047C155.25 24.9128 152.504 18.4195 147.012 13.2248C141.809 7.74161 135.305 5 127.5 5C119.984 5 113.48 7.74161 107.988 13.2248C102.496 18.4195 99.75 24.9128 99.75 32.7047C99.75 40.2081 102.496 46.7013 107.988 52.1846C113.48 57.6678 119.984 60.4094 127.5 60.4094C135.305 60.4094 141.809 57.6678 147.012 52.1846C152.504 46.7013 155.25 40.2081 155.25 32.7047Z"
        fill="currentColor"
      />
      <path
        className={styles.whiteColor}
        d="M159 112.96V131H48V112.96L48.4336 109.094C49.0117 105.944 50.168 102.937 51.9023 100.074C53.9258 95.7785 56.6719 92.0559 60.1406 88.906C64.4766 85.1834 69.9687 82.1767 76.6172 79.8859C84.1328 77.3087 93.0938 76.0201 103.5 76.0201C119.977 76.0201 132.984 79.17 142.523 85.4698C149.461 90.0515 154.375 96.0649 157.266 103.51C158.422 106.946 159 110.096 159 112.96ZM131.25 30.4899C131.25 22.7584 128.504 16.3154 123.012 11.1611C117.809 5.72036 111.305 3 103.5 3C95.9844 3 89.4805 5.72036 83.9883 11.1611C78.4961 16.3154 75.75 22.7584 75.75 30.4899C75.75 37.9351 78.4961 44.3781 83.9883 49.8188C89.4805 55.2595 95.9844 57.9799 103.5 57.9799C111.305 57.9799 117.809 55.2595 123.012 49.8188C128.504 44.3781 131.25 37.9351 131.25 30.4899Z"
        fill="currentColor"
      />
      <path
        className={styles.primaryColor30}
        d="M159 112.96V131H48V112.96L48.4336 109.094C49.0117 105.944 50.168 102.937 51.9023 100.074C53.9258 95.7785 56.6719 92.0559 60.1406 88.906C64.4766 85.1834 69.9687 82.1767 76.6172 79.8859C84.1328 77.3087 93.0938 76.0201 103.5 76.0201C119.977 76.0201 132.984 79.17 142.523 85.4698C149.461 90.0515 154.375 96.0649 157.266 103.51C158.422 106.946 159 110.096 159 112.96ZM131.25 30.4899C131.25 22.7584 128.504 16.3154 123.012 11.1611C117.809 5.72036 111.305 3 103.5 3C95.9844 3 89.4805 5.72036 83.9883 11.1611C78.4961 16.3154 75.75 22.7584 75.75 30.4899C75.75 37.9351 78.4961 44.3781 83.9883 49.8188C89.4805 55.2595 95.9844 57.9799 103.5 57.9799C111.305 57.9799 117.809 55.2595 123.012 49.8188C128.504 44.3781 131.25 37.9351 131.25 30.4899Z"
        stroke="currentColor"
        stroke-width="6"
      />
      <path
        className={styles.accentColor20}
        d="M69 52.9545L58.65 40L25.4438 73.25L10.7812 58.5682L0 69.3636L25.4438 97L69 52.9545Z"
        fill="currentColor"
      />
    </svg>
  );
};
