import {
  Button,
  ButtonVariant,
  Select,
  Label,
  LabelSize,
  SelectSize,
  Icon,
  IconName,
  ButtonIconAlign,
} from "@eightfold.ai/octuple";
import React, { useState, useEffect, useContext } from "react";
import { secondaryButtonStyle } from "../../commons/commonStyles";
import { useNavigate } from "react-router";
import { globalContext } from "../../store";

import { updateDemographicData } from '../../commons/commonFuncions'


const Company = (props: any) => {
  const navigate = useNavigate();

  const { globalState, dispatch } = useContext(globalContext);
  const [orgType, setOrgType] = useState<any>("");
  const [orgStructure, setOrgStructure] = useState<any>("");
  const [orgSize, setOrgSize] = useState<any>("");
  const [orgCycle, setOrgCycle] = useState<any>("");
  const [orgRevenue, setOrgRevenue] = useState<any>("");
  const [workingEnvironment, setWorkingEnvironment] = useState<any>("");
  const [errorMsg, setErrorMsg] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (props) {
      setOrgType(props?.orgType);
      setOrgStructure(props?.orgStructure);
      setOrgSize(props?.orgSize);
      setOrgCycle(props?.orgCycle);
      setOrgRevenue(props?.orgRevenue);
      setWorkingEnvironment(props?.workingEnvironment);
    }
  }, [props]);

  const saveCompanyData = () => {
    const payload = {
      demographicSurveyData: {
        orgType: orgType,
        orgStructure: orgStructure,
        orgSize: orgSize,
        orgCycle: orgCycle,
        orgRevenue: orgRevenue,
        workingEnvironment: workingEnvironment,
      }
    };

    const updatedDataForContext: any = {
      demographicSurveyData: {
        ...globalState?.leaderData?.demographicSurveyData,
        orgType: orgType,
        orgStructure: orgStructure,
        orgSize: orgSize,
        orgCycle: orgCycle,
        orgRevenue: orgRevenue,
        workingEnvironment: workingEnvironment,
      }
    };

    const newContextData = { ...globalState?.leaderData, ...updatedDataForContext }
    const redirectUrl = '/register-personal'
    updateDemographicData(payload, props.leaderProfileId, redirectUrl, setErrorMsg, newContextData, dispatch, navigate, setShowLoader);
  };

  const backtoRolePage = () => {
    navigate('/register-role');
  }

  return (
    <div className="accountWrapper">
      <h1>Company</h1>
      <h2>About your current or most recent company</h2>
      <h6>*Required</h6>

      {props?.orgTypeOptions?.length > 0 &&
        <>
          <div className="fieldWrap">
            <Label htmlFor="orgType" size={LabelSize.Medium} text="Organization Type" />
            <Select
              id="orgType"
              defaultValue={props?.orgType}
              onOptionsChange={(e: any) => {
                setOrgType(e?.[0]);
              }}
              options={props?.orgTypeOptions}
              classNames="selectField"
              size={SelectSize.Large}
            />
          </div>

          <div className="fieldWrap">
            <Label
              htmlFor="orgStructure"
              size={LabelSize.Medium}
              text="Organization Structure"
            />
            <Select
              id="orgStructure"
              defaultValue={props?.orgStructure}
              onOptionsChange={(e: any) => {
                setOrgStructure(e?.[0]);
              }}
              options={props?.orgStructureOptions}
              classNames="selectField"
              size={SelectSize.Large}
            />
          </div>

          <div className="fieldWrap">
            <Label htmlFor="orgSize" size={LabelSize.Medium} text="Organization Size" />
            <Select
              id="orgSize"
              defaultValue={props?.orgSize}
              onOptionsChange={(e: any) => {
                setOrgSize(e?.[0]);
              }}
              options={props?.orgSizeOptions}
              classNames="selectField"
              size={SelectSize.Large}
            />
          </div>

          <div className="fieldWrap">
            <Label
              htmlFor="orgCycle"
              size={LabelSize.Medium}
              text="Cycle/Organization Cycle"
            />
            <Select
              id='orgCycle'
              defaultValue={props?.orgCycle}
              onOptionsChange={(e: any) => {
                setOrgCycle(e?.[0]);
              }}
              options={props?.orgCycleOptions}
              classNames="selectField"
              size={SelectSize.Large}
            />
          </div>

          <div className="fieldWrap">
            <Label htmlFor="orgRevenue" size={LabelSize.Medium} text="Revenue" />
            <Select
              id='orgRevenue'
              defaultValue={props?.orgRevenue}
              onOptionsChange={(e: any) => {
                setOrgRevenue(e?.[0]);
              }}
              options={props?.orgRevenueOptions}
              classNames="selectField"
              size={SelectSize.Large}
            />
          </div>

          <div className="fieldWrap">
            <Label htmlFor="workingEnvironment" size={LabelSize.Medium} text="Working Environment" />
            <Select
              id="workingEnvironment"
              defaultValue={props?.workingEnvironment}
              onOptionsChange={(e: any) => {
                setWorkingEnvironment(e?.[0]);
              }}
              options={props?.workingEnvironmentOptions}
              classNames="selectField"
              size={SelectSize.Large}
            />
          </div>

          <div className="fieldWrap-Btn">
            <Icon
              classNames="secondaryButton"
              color="#BA3A19"
              data-test-id="myIconTestId"
              id="myIconId"
              path={IconName.mdiChevronLeft}
              role="presentation"
              rotate={0}
              size="20px"
              title="My icon title."
            />
            <Button
              text="Back"
              variant={ButtonVariant.SystemUI}
              classNames="backButton"
              // style={secondaryButtonStyle}
              onClick={() => backtoRolePage()}
              transparent={true}
            />
            <Button
              text="Continue"
              variant={ButtonVariant.Secondary}
              classNames="secondaryButton"
              style={secondaryButtonStyle}
              onClick={() => {
                saveCompanyData();
              }}
              iconProps={(showLoader) ? { path: IconName.mdiLoading, spin: true } : undefined}
              alignIcon={ButtonIconAlign.Right}
              disabled={showLoader}
            />
          </div>
        </>
      }
      {errorMsg && <span className="apiErrorMessage">An error occurred</span>}

    </div>
  );
};

export default Company;