import { Spinner } from "@eightfold.ai/octuple";
import React from "react";

const Loader = () => {
  return (
    <div className="loaderWrap">
      <Spinner
        classNames="my-spinner-class"
        size="64px"
      />
    </div>
  );
}

export default Loader;