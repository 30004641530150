import React from 'react'
import { Row, Col } from "@eightfold.ai/octuple";

const ReportCard = (props: any) => {

  const gotoSupport = () => {
    window.open(`https://heidricksupport.zendesk.com/hc/en-us`, "_blank", "noopener,noreferrer");
  }

  return (
    <Col classNames="gridRightColumn">
      <Row classNames="gridRightRow">
        <Col
          classNames="gridRightRowsColumnDetails"
          flex="1 1 200px"
        >
          <h2>Reports</h2>
          {props?.reportsData && props?.reportsData?.length ?

            <>{
              props?.reportsData.map((item: any) => {
                return <>
                  <Row classNames="gridRightChildRow">
                    <Col classNames="gridRightChildDetails">
                      <h3>{item.name}</h3>
                      <p className="paragraphStyle">Generated: {props?.formatDate(item.reportDate)}</p>
                    </Col>
                    <Col classNames="actionItem">
                    </Col>
                  </Row>
                </>
              })
            }

            </>
            :
            <Row classNames="emptyReportsState">
              <Col classNames="emptyStateLogo">
                <img className="classEllipse" src={`./../assets/img/Ellipse3.svg`} alt="" />
                <img className="classGroup1" src={`./../assets/img/Group1.svg`} alt="" />
                <img className="classGroup2" src={`./../assets/img/Group2.svg`} alt="" />
                <h3>You don't have any reports yet</h3>
              </Col>
            </Row>}


        </Col>
      </Row>

      <Row classNames="gridRightCard">
        <Col classNames="gridCardDetails">
          <h2 className="header2Style">Need Help?</h2>
          <p className="paragraphStyle">For questions or general support <a className="paragraphStyle" href="javascript:void(0)" onClick={() => gotoSupport()} >click here</a></p>
        </Col>
      </Row>
    </Col>

  )
}

export default ReportCard;