import React from 'react';
import styles from '../empty.module.scss';

export const DefaultEmptyDataImg = (): JSX.Element => {
  return (
    <svg
      className={'empty-data-image-default'}
      fill="none"
      height="184"
      width="200"
      viewBox="0 0 200 184"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={styles.primaryColor10}
        d="M39.0718 177.839C21.8078 171.861 3.66074 162.325 0.510726 150.175C-2.63929 138.025 9.18787 123.359 24.8068 115.637C40.5358 108.029 59.9069 107.449 71.9662 102.257C83.9156 96.9498 88.5731 86.9309 99.9189 77.9985C111.265 69.066 129.299 61.2199 140.372 64.9517C151.555 68.798 155.648 84.2069 164.716 96.6426C173.785 109.078 187.68 118.625 194.904 131.657C202.237 144.804 202.768 161.422 189.061 166.219C175.355 171.016 147.3 163.877 128.36 165.625C109.569 167.288 99.7624 177.823 86.4541 181.955C73.2954 186.004 56.4854 183.734 39.0718 177.839Z"
        fill="currentColor"
      />
      <path
        className={styles.whiteColor}
        d="M26 108L38.5 59H160.5L174 108V154H26V108Z"
        fill="currentColor"
      />
      <path
        className={styles.accentColor20}
        clipRule="evenodd"
        d="M118.495 108.258C118.495 117.504 110.214 123.333 99.9993 123.333C89.7843 123.333 81.5034 117.504 81.5034 108.258C81.5034 107.958 81.5121 105.994 81.5293 105.698H46.666L60.2683 72.6258C60.8547 71.0563 62.4813 70 64.3116 70H135.687C137.517 70 139.144 71.0563 139.73 72.6258L153.333 105.698H118.469C118.487 105.994 118.495 107.958 118.495 108.258Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        className={styles.primaryColor30}
        clipRule="evenodd"
        d="M152.505 59.9999L47.4938 60.0006C43.6128 60.0006 40.0926 62.6542 38.7909 66.7166L27.5922 101.666L74.0905 101.666C78.722 101.666 83.0934 103.873 86.0399 107.654L92.9658 116.542C96.6469 121.266 103.355 121.266 107.037 116.542L113.962 107.654C116.909 103.873 121.28 101.666 125.912 101.666L172.408 101.666L161.208 66.7158C159.906 62.6534 156.386 59.9999 152.505 59.9999ZM173.651 108.333L125.912 108.333C123.217 108.333 120.638 109.615 118.876 111.876L111.95 120.763C105.729 128.747 94.2733 128.747 88.0518 120.763L81.126 111.876C79.3647 109.615 76.7854 108.333 74.0906 108.333L26.3492 108.333L26.3493 143.333C26.3493 148.934 30.548 153.333 35.5634 153.333L164.436 153.333C169.452 153.333 173.65 148.934 173.651 143.333L173.651 108.333ZM47.4937 53.3339L152.505 53.3333C159.195 53.3332 165.081 57.8972 167.226 64.5898L180 104.454L180 143.333C180 152.46 173.105 159.999 164.436 159.999L35.5635 160C26.8946 160 20.0001 152.46 20 143.333L20 104.454L32.7731 64.5908C34.9176 57.898 40.804 53.334 47.4937 53.3339Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <rect
        className={styles.accentColor20}
        fill="currentColor"
        height="40"
        rx="6.66667"
        width="13.3333"
        x="93.334"
      />
      <rect
        className={styles.accentColor20}
        fill="currentColor"
        height="40"
        rx="6.66667"
        transform="rotate(-30 46.666 6.66675)"
        width="13.3333"
        x="46.666"
        y="6.66675"
      />
      <rect
        className={styles.accentColor20}
        fill="currentColor"
        height="40"
        rx="6.66667"
        transform="rotate(30 141.666 0)"
        width="13.3333"
        x="141.666"
      />
    </svg>
  );
};
