import React from "react";

const Footer = () => {
  const originHost = window.location.origin;

  const onClickFooterItems = (url: any) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const footerValues = [
    {
      url: "Privacy Notice",
      onClick: () => {
        onClickFooterItems(`${originHost}/assets/pdf/privacy-notice.pdf`);
      },
    },
    {
      url: "Terms of Use",
      onClick: () => {
        onClickFooterItems(`${originHost}/assets/pdf/terms-of-use.pdf`);
      },
    },
    {
      url: "Contact",
      onClick: () => {
        onClickFooterItems(`https://heidricksupport.zendesk.com/hc/en-us`);
      },
    },
  ];

  return (
    <div className="footerWrapper">
      <div className="footerTitle">© Heidrick & Struggles</div>
      <div className="footerLinks">
        {footerValues.map((item, index) => {
          const lengthCheck = index + 1 === footerValues.length;

          return (
            <>
              <div
                className={lengthCheck ? "footerPointer" : "footerSubTitle"}
                onClick={item.onClick}
              >
                {item.url}
              </div>
              {lengthCheck ? <></> : <div className="footerDivider"></div>}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default Footer;
