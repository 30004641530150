import Api from '../Api'

export const updateDemographicData = (payload: any, leaderProfileId: any, redirectUrl: string, setErrorMsg: any, newContextData: any, dispatch: any, navigate: any, setShowLoader: any) => {
  setShowLoader(true);
  return Api.updateLeaderProfile(payload, leaderProfileId)
    .then((res: any) => {
      if (res && res.success) {
        dispatch({
          type: "SET_LEADER_DATA",
          payload: newContextData,
        });
        navigate(redirectUrl);
      } else {
        setShowLoader(false);
        setErrorMsg(true);
        setTimeout(() => {
          setErrorMsg(false);
        }, 5000);
      }
    })
    .catch((err: any) => {
      console.log(err);
      setShowLoader(false);
    });
}

export const checkDropDownValues = (name: any, lookUpData: any) => {
  let dropDownData: any = []
  lookUpData?.map((item: any) => {
    if (item.dropDownName == name) {
      dropDownData = item.dropDownValues
    }
  })
  return dropDownData
}

export const checkSubDropDownValues = (
  selectedValue: any,
  name: any,
  lookUpData: any
) => {
  let dropDownData: any = [];
  lookUpData?.map((item: any) => {
    if (item.dropDownName == name) {
      item.dropDownValues.map((i: any) => {
        if (i.value === selectedValue) {
          dropDownData = i.subList;
        }
      });
    }
  });
  return dropDownData;
};

