import React from 'react';
import styles from '../empty.module.scss';

export const DefaultEmptyMessagesImg = (): JSX.Element => {
  return (
    <svg
      className={'empty-messages-image-default'}
      fill="none"
      height="184"
      width="200"
      viewBox="0 0 200 184"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={styles.primaryColor10}
        d="M39.0718 177.839C21.8078 171.861 3.66074 162.325 0.510726 150.175C-2.63929 138.025 9.18787 123.359 24.8068 115.637C40.5358 108.029 59.9069 107.449 71.9662 102.257C83.9156 96.9498 88.5731 86.9309 99.9189 77.9985C111.265 69.066 129.299 61.2199 140.372 64.9517C151.555 68.798 155.648 84.2069 164.716 96.6426C173.785 109.078 187.68 118.625 194.904 131.657C202.237 144.804 202.768 161.422 189.061 166.219C175.355 171.016 147.3 163.877 128.36 165.625C109.569 167.288 99.7624 177.823 86.4541 181.955C73.2954 186.004 56.4854 183.734 39.0718 177.839Z"
        fill="currentColor"
      />
      <path
        className={styles.whiteColorFillPrimaryColor30Stroke}
        d="M131.098 145.069C125.349 140.58 119.665 136.448 116.52 133.384C116.016 132.893 115.288 132.671 114.598 132.81C35.5718 148.779 -9.97428 65.6821 65.669 27.1035C65.7558 27.0592 65.8525 27.0185 65.9449 26.9875C142.983 1.22162 204.241 81.5443 135.362 124.844C134.772 125.214 134.381 125.876 134.381 126.572L134.381 143.483C134.381 145.166 132.424 146.105 131.098 145.069Z"
        strokeWidth="6"
      />
      <path
        className={styles.accentColor20}
        d="M66.3926 86.6624C70.9586 86.6624 74.3926 82.703 74.3926 78.1624C74.3926 73.6217 70.9586 69.6624 66.3926 69.6624C61.8266 69.6624 58.3926 73.6217 58.3926 78.1624C58.3926 82.703 61.8266 86.6624 66.3926 86.6624Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="4"
      />
      <path
        className={styles.accentColor20}
        d="M94.3926 86.6624C98.9586 86.6624 102.393 82.703 102.393 78.1624C102.393 73.6217 98.9586 69.6624 94.3926 69.6624C89.8266 69.6624 86.3926 73.6217 86.3926 78.1624C86.3926 82.703 89.8266 86.6624 94.3926 86.6624Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="4"
      />
      <path
        className={styles.accentColor20}
        d="M122.393 86.6624C126.959 86.6624 130.393 82.703 130.393 78.1624C130.393 73.6217 126.959 69.6624 122.393 69.6624C117.827 69.6624 114.393 73.6217 114.393 78.1624C114.393 82.703 117.827 86.6624 122.393 86.6624Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="4"
      />
    </svg>
  );
};
