import React from 'react';

export const CopilotFullLogoMedium = (): JSX.Element => {
  return (
    <svg
      className={'copilot-full-logo-medium'}
      fill="none"
      height="16"
      width="65"
      viewBox="0 0 65 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.0689 6.34842C23.4004 6.34842 23.6807 6.38405 23.9019 6.45488C24.1257 6.52614 24.3981 6.64476 24.7114 6.80596C24.824 6.86723 24.9383 6.89851 25.0521 6.89851C25.2094 6.89851 25.3502 6.83247 25.4697 6.70255C25.5883 6.57393 25.6483 6.42924 25.6483 6.27325C25.6483 6.04514 25.544 5.86177 25.3376 5.72751C25.0178 5.52416 24.6662 5.35426 24.293 5.22348C23.9184 5.09226 23.4674 5.02534 22.9529 5.02534C22.2537 5.02534 21.5989 5.18307 21.0057 5.49418C20.4122 5.80572 19.9311 6.25935 19.5765 6.8429C19.2215 7.42644 19.0412 8.12123 19.0412 8.90812C19.0412 9.69502 19.2211 10.3898 19.5765 10.9734C19.9316 11.5569 20.4122 12.0105 21.0057 12.3221C21.598 12.6327 22.2533 12.7905 22.9529 12.7905C23.4674 12.7905 23.918 12.724 24.293 12.5923C24.6662 12.4611 25.0178 12.2917 25.338 12.0879C25.544 11.954 25.6483 11.7707 25.6483 11.5426C25.6483 11.387 25.5883 11.2423 25.4697 11.1133C25.3498 10.9833 25.2094 10.9173 25.0521 10.9173C24.9387 10.9173 24.824 10.9486 24.7123 11.0094C24.399 11.1711 24.1261 11.2892 23.9019 11.3609C23.6803 11.4313 23.4 11.4674 23.0689 11.4674C22.2554 11.4674 21.6089 11.231 21.1474 10.7644C20.6855 10.2981 20.4513 9.6733 20.4513 8.90769C20.4513 8.14208 20.6855 7.51726 21.1474 7.05103C21.6089 6.5848 22.2554 6.34799 23.0689 6.34799V6.34842Z"
        fill="#1A212E"
      />
      <path
        d="M32.2706 5.50937C31.7062 5.18827 31.0457 5.02576 30.3079 5.02576C29.57 5.02576 28.8948 5.18871 28.3307 5.50937C27.7654 5.83091 27.3235 6.2928 27.0167 6.88199C26.7104 7.46945 26.5552 8.1512 26.5552 8.90811C26.5552 9.66503 26.7104 10.3472 27.0167 10.9347C27.3231 11.5239 27.7654 11.9853 28.3307 12.3069C28.8943 12.628 29.5596 12.7905 30.3079 12.7905C31.0561 12.7905 31.7066 12.6275 32.2706 12.3069C32.8355 11.9857 33.2801 11.5239 33.5916 10.9347C33.9028 10.3468 34.0605 9.66503 34.0605 8.90811C34.0605 8.1512 33.9028 7.46945 33.5916 6.88156C33.2801 6.29236 32.8355 5.83091 32.2706 5.50937ZM32.6509 8.89377C32.6509 9.49036 32.5335 9.98874 32.3028 10.3759C32.0721 10.7622 31.7762 11.0485 31.4237 11.2271C31.07 11.4061 30.6946 11.4974 30.3079 11.4974C29.9211 11.4974 29.5314 11.4065 29.1776 11.2271C28.8257 11.0489 28.5324 10.7626 28.3064 10.3763C28.08 9.98961 27.9653 9.49079 27.9653 8.89377C27.9653 8.29676 28.08 7.81271 28.3064 7.426C28.5328 7.04015 28.8257 6.75642 29.1772 6.58261C29.5314 6.40794 29.912 6.31974 30.3079 6.31974C30.7037 6.31974 31.07 6.40838 31.4242 6.58261C31.7762 6.75642 32.0716 7.04059 32.3028 7.42643C32.534 7.81358 32.6509 8.30719 32.6509 8.89421V8.89377Z"
        fill="#1A212E"
      />
      <path
        d="M41.2255 5.48808C40.6854 5.18132 40.1053 5.02576 39.5017 5.02576C38.5822 5.02576 37.7658 5.37337 37.0722 6.0599V5.79615C37.0722 5.60062 37.0018 5.43334 36.8624 5.29951C36.7237 5.16611 36.5547 5.09833 36.36 5.09833C36.1654 5.09833 35.9981 5.16611 35.8642 5.29994C35.7304 5.43377 35.6626 5.60062 35.6626 5.79572V15.2928C35.6626 15.4874 35.73 15.6565 35.8634 15.7951C35.9972 15.9341 36.1724 16 36.3684 16C36.5643 16 36.7363 15.9367 36.8706 15.8029C37.0045 15.6686 37.0722 15.497 37.0722 15.2928V11.7572C37.7653 12.4433 38.5822 12.7909 39.5017 12.7909C40.1057 12.7909 40.6854 12.6353 41.2255 12.329C41.7661 12.0222 42.2102 11.5682 42.5456 10.9799C42.8811 10.392 43.051 9.69501 43.051 8.90855C43.051 8.12208 42.8811 7.42556 42.5456 6.83724C42.2102 6.24891 41.7656 5.79485 41.2255 5.48808ZM41.6418 8.90811C41.6418 9.72239 41.4267 10.3594 41.003 10.8017C40.5802 11.2436 40.0119 11.4678 39.3136 11.4678C38.879 11.4678 38.4645 11.3696 38.0821 11.1763C37.7028 10.9842 37.3629 10.7265 37.0722 10.4106V7.40601C37.3629 7.09012 37.7028 6.83246 38.0821 6.64041C38.4654 6.44661 38.8795 6.34841 39.3136 6.34841C40.0114 6.34841 40.5798 6.57262 41.003 7.01452C41.4271 7.45728 41.6418 8.09428 41.6418 8.90811Z"
        fill="#1A212E"
      />
      <path
        d="M45.4083 2.13018H45.3214C45.1067 2.13018 44.9203 2.20796 44.7673 2.36091C44.6144 2.51429 44.5366 2.70026 44.5366 2.91491V2.9727C44.5366 3.18691 44.6144 3.37332 44.7673 3.52626C44.9203 3.67921 45.1067 3.75699 45.3214 3.75699H45.4083C45.6229 3.75699 45.8094 3.67921 45.9623 3.52626C46.1153 3.37332 46.193 3.18691 46.193 2.9727V2.91491C46.193 2.7007 46.1153 2.51429 45.9623 2.36091C45.8094 2.20796 45.6229 2.13018 45.4083 2.13018Z"
        fill="#1A212E"
      />
      <path
        d="M45.3505 5.09832C45.1545 5.09832 44.9876 5.16872 44.8538 5.30776C44.7204 5.44637 44.653 5.61539 44.653 5.81005V12.0209C44.653 12.216 44.7208 12.3829 44.8542 12.5167C44.9881 12.6505 45.1549 12.7183 45.35 12.7183C45.5451 12.7183 45.7137 12.6505 45.8528 12.5172C45.9918 12.3833 46.0622 12.216 46.0622 12.0205V5.80962C46.0622 5.60496 45.9944 5.43333 45.8602 5.29907C45.7259 5.16524 45.5543 5.09746 45.35 5.09746L45.3505 5.09832Z"
        fill="#1A212E"
      />
      <path
        d="M48.8106 1.91336C48.6151 1.91336 48.4478 1.98375 48.3139 2.1228C48.1805 2.26141 48.1132 2.43043 48.1132 2.62509V12.0209C48.1132 12.216 48.181 12.3829 48.3148 12.5167C48.4486 12.6505 48.6155 12.7183 48.8106 12.7183C49.0057 12.7183 49.1743 12.6505 49.3129 12.5171C49.452 12.3833 49.5224 12.216 49.5224 12.0205V2.62509C49.5224 2.42087 49.4546 2.2488 49.3208 2.11454C49.1865 1.98071 49.0148 1.91293 48.8106 1.91293V1.91336Z"
        fill="#1A212E"
      />
      <path
        d="M56.8391 5.50937C56.2746 5.18827 55.6142 5.02576 54.8763 5.02576C54.1385 5.02576 53.4632 5.18871 52.8992 5.50937C52.3339 5.83091 51.8919 6.2928 51.5852 6.88199C51.2788 7.46945 51.1237 8.1512 51.1237 8.90811C51.1237 9.66503 51.2788 10.3472 51.5852 10.9347C51.8915 11.5239 52.3339 11.9853 52.8992 12.3069C53.4628 12.628 54.1281 12.7905 54.8763 12.7905C55.6246 12.7905 56.2751 12.6275 56.8391 12.3069C57.404 11.9857 57.8485 11.5239 58.1601 10.9347C58.4712 10.3468 58.629 9.66503 58.629 8.90811C58.629 8.1512 58.4712 7.46945 58.1601 6.88156C57.8485 6.29236 57.404 5.83091 56.8391 5.50937ZM57.2189 8.89377C57.2189 9.49036 57.1016 9.98874 56.8708 10.3759C56.6401 10.7622 56.3442 11.0485 55.9918 11.2271C55.6381 11.4061 55.2626 11.4974 54.8759 11.4974C54.4892 11.4974 54.0994 11.4065 53.7457 11.2271C53.3937 11.0489 53.1004 10.7626 52.8744 10.3763C52.648 9.98961 52.5333 9.49079 52.5333 8.89377C52.5333 8.29676 52.648 7.81271 52.8744 7.426C53.1008 7.04015 53.3937 6.75642 53.7452 6.58261C54.0994 6.40794 54.48 6.31974 54.8759 6.31974C55.2717 6.31974 55.6381 6.40838 55.9922 6.58261C56.3442 6.75642 56.6397 7.04059 56.8704 7.42643C57.1016 7.81358 57.2185 8.30719 57.2185 8.89421L57.2189 8.89377Z"
        fill="#1A212E"
      />
      <path
        d="M64.1741 11.5751C64.0594 11.4656 63.9273 11.4105 63.7804 11.4105C63.71 11.4105 63.6509 11.4157 63.6036 11.4261C63.5601 11.4361 63.5184 11.4457 63.4771 11.4561C63.2898 11.5126 63.0995 11.5408 62.9114 11.5408C62.6502 11.5408 62.4316 11.4917 62.2609 11.3948C62.0988 11.3027 62.0162 11.075 62.0162 10.7183V6.3923H63.6062C63.7826 6.3923 63.9303 6.33147 64.045 6.21154C64.1593 6.09249 64.2171 5.9478 64.2171 5.78138C64.2171 5.6054 64.1589 5.4581 64.0446 5.34339C63.9299 5.22868 63.7826 5.17089 63.6066 5.17089H62.0166V4.05855C62.0166 3.96991 61.9858 3.89126 61.925 3.82478C61.8628 3.757 61.7855 3.7231 61.6955 3.7231C61.6056 3.7231 61.5139 3.77003 61.4174 3.86649L59.4503 5.79007C59.3534 5.86654 59.3043 5.96083 59.3043 6.0712C59.3043 6.16071 59.3356 6.23718 59.3973 6.29888C59.459 6.36102 59.5355 6.3923 59.6254 6.3923H60.6075V10.8339C60.6075 12.1326 61.334 12.7909 62.7675 12.7909C63.1973 12.7909 63.6079 12.7018 63.9903 12.525C64.2276 12.4011 64.3484 12.2169 64.3484 11.9775C64.3484 11.8202 64.2901 11.6846 64.175 11.5751H64.1741Z"
        fill="#1A212E"
      />
      <path
        d="M11.2779 13.3162L11.1021 11.0684L8.10709 10.8345C6.3563 10.6969 5.07002 10.1491 4.48526 9.29196C3.51979 7.87012 3.67086 5.98648 4.84514 4.8129L2.12705 2.12163C-0.354795 4.6029 -0.708506 8.51029 1.29668 11.4607C1.29668 11.4607 1.29711 11.4616 1.29755 11.4616C1.29755 11.462 1.29798 11.4625 1.29841 11.4629C2.18705 12.7658 4.03682 14.3812 7.80582 14.6777L13.0408 15.087C12.114 15.011 11.353 14.2727 11.2779 13.3162Z"
        fill="#1A212E"
      />
      <path
        d="M15.1157 13.0023C15.117 13.0162 15.1218 13.0293 15.1226 13.0432L14.7135 7.80837C14.4172 4.03985 12.8023 2.19032 11.4999 1.30179C11.4994 1.30179 11.499 1.30136 11.4986 1.30092C11.4986 1.30092 11.4977 1.30049 11.4977 1.30006C8.5483 -0.705307 4.60666 -0.359469 2.12669 2.12163L4.83154 4.826C6.00514 3.65186 7.90835 3.52288 9.32969 4.48824C10.1861 5.07292 10.7337 6.35904 10.8712 8.10961L11.2808 13.3526C11.3641 14.4144 12.2912 15.2074 13.352 15.1245C13.8289 15.0871 14.251 14.8792 14.5643 14.5654C14.8779 14.252 15.0858 13.8297 15.1231 13.3526C15.1326 13.2337 15.127 13.1169 15.1153 13.0023H15.1157Z"
        fill="#1A212E"
      />
    </svg>
  );
};
