import React from "react";
import Header from "../commons/Header";
import Footer from "../commons/Footer";
import { Link } from "react-router-dom";

const InviteExpired = () => {
  const headerLogo = `./../assets/img/header-img.svg`;
  const title = "Leader Portal";

  return (
    <div className="mainWrapper">
      <div className="imgWrapper">
        <img
          alt="Landing graphic"
          src={`./../assets/img/onboarding-image.png`}
        />
      </div>
      <div className="rightWrapper">
        <Header headerLogo={headerLogo} title={title} />
        <div className="contentWrapper">
          <h1 className="titleStyle">Your activation link has expired</h1>
          <div className="middleContent">
            <p className="welcomeContent">
              To receive a new activation link, please&nbsp;
              <Link
                className='paragraphStyle'
                rel='noreferrer'
                to={'https://heidricksupport.zendesk.com/hc/en-us'}
                target='_blank'
              >
                Contact Support</Link>
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </div >
  );
};

export default InviteExpired;
