import { OcBaseTheme, OcTheme, OcThemeName } from './Theming.types';

// NOTE: Theme should not provide defaults. The css variables provide
// the defaults, and the ConfigProvider theme can override them accordingly.
// Sunset of themeDefaults is planned for Octuple v3
export const themeDefaults: OcBaseTheme = {};

export const red: OcTheme = {
  primaryColor: '#6c2222',
  accentName: 'violetRed',
  ...themeDefaults,
  gradientEndPalette: [
    '#fff2ed',
    '#ffd1bd',
    '#ffb396',
    '#d26743',
    '#a54d2c',
    '#78351a',
  ],
  gradientMiddlePalette: [
    '#ffefef',
    '#ffc6c6',
    '#ffa3a3',
    '#c15151',
    '#993838',
    '#6c2222',
  ],
  gradientStartPalette: [
    '#fff0f9',
    '#fcc8e7',
    '#f3a5d4',
    '#b5548e',
    '#943d71',
    '#702853',
  ],
  palette: [
    '#ffefef',
    '#ffc6c6',
    '#ffa3a3',
    '#f48686',
    '#e46f6f',
    '#c45353',
    '#993838',
    '#6c2222',
    '#3b1010',
    '#0a0202',
  ],
};

export const redOrange: OcTheme = {
  primaryColor: '#78351a',
  accentName: 'red',
  ...themeDefaults,
  gradientEndPalette: [
    '#fff8eb',
    '#ffe3b0',
    '#ffcd78',
    '#c97e19',
    '#9d6309',
    '#6c4500',
  ],
  gradientMiddlePalette: [
    '#fff2ed',
    '#ffd1bd',
    '#ffb396',
    '#d26743',
    '#a54d2c',
    '#78351a',
  ],
  gradientStartPalette: [
    '#ffefef',
    '#ffc6c6',
    '#ffa3a3',
    '#c15151',
    '#993838',
    '#6c2222',
  ],
  palette: [
    '#fff2ed',
    '#ffd1bd',
    '#ffb396',
    '#ff9977',
    '#f8845f',
    '#d26743',
    '#a54d2c',
    '#78351a',
    '#4c1f0c',
    '#1f0c03',
  ],
};

export const orange: OcTheme = {
  primaryColor: '#6c4500',
  accentName: 'redOrange',
  ...themeDefaults,
  gradientEndPalette: [
    '#ffffeb',
    '#fff89c',
    '#ffe44e',
    '#a88f00',
    '#857600',
    '#5c5500',
  ],
  gradientMiddlePalette: [
    '#fff8eb',
    '#ffe3b0',
    '#ffcd78',
    '#c97e19',
    '#9d6309',
    '#6c4500',
  ],
  gradientStartPalette: [
    '#fff2ed',
    '#ffd1bd',
    '#ffb396',
    '#d26743',
    '#a54d2c',
    '#78351a',
  ],
  palette: [
    '#fff8eb',
    '#ffe3b0',
    '#ffcd78',
    '#ffb650',
    '#f29d31',
    '#ce811a',
    '#9d6309',
    '#6c4500',
    '#3b2600',
    '#0a0700',
  ],
};

export const yellow: OcTheme = {
  primaryColor: '#5c5500',
  accentName: 'orange',
  ...themeDefaults,
  gradientEndPalette: [
    '#fdfeeb',
    '#f3f5b0',
    '#e2e575',
    '#858707',
    '#6d6f00',
    '#525400',
  ],
  gradientMiddlePalette: [
    '#ffffeb',
    '#fff89c',
    '#ffe44e',
    '#a88f00',
    '#857600',
    '#5c5500',
  ],
  gradientStartPalette: [
    '#fff8eb',
    '#ffe3b0',
    '#ffcd78',
    '#c97e19',
    '#9d6309',
    '#6c4500',
  ],
  palette: [
    '#ffffeb',
    '#fff89c',
    '#ffe44e',
    '#f1c40f',
    '#d6ad00',
    '#ad9300',
    '#857600',
    '#5c5500',
    '#333100',
    '#0a0a00',
  ],
};

export const yellowGreen: OcTheme = {
  primaryColor: '#525400',
  accentName: 'yellow',
  ...themeDefaults,
  gradientEndPalette: [
    '#f0fefa',
    '#b9f4e4',
    '#8ce1ca',
    '#3d8f79',
    '#2b715f',
    '#1b5143',
  ],
  gradientMiddlePalette: [
    '#fdfeeb',
    '#f3f5b0',
    '#e2e575',
    '#858707',
    '#6d6f00',
    '#525400',
  ],
  gradientStartPalette: [
    '#ffffeb',
    '#fff89c',
    '#ffe44e',
    '#a88f00',
    '#857600',
    '#5c5500',
  ],
  palette: [
    '#fdfeeb',
    '#f3f5b0',
    '#e2e575',
    '#cacd46',
    '#abae1f',
    '#858707',
    '#6d6f00',
    '#525400',
    '#343500',
    '#131300',
  ],
};

export const green: OcTheme = {
  primaryColor: '#1b5143',
  accentName: 'yellowGreen',
  ...themeDefaults,
  gradientEndPalette: [
    '#ebfdff',
    '#b0f3fe',
    '#7be4f4',
    '#1999ac',
    '#0b7b8b',
    '#025966',
  ],
  gradientMiddlePalette: [
    '#f0fefa',
    '#b9f4e4',
    '#8ce1ca',
    '#3d8f79',
    '#2b715f',
    '#1b5143',
  ],
  gradientStartPalette: [
    '#fdfeeb',
    '#f3f5b0',
    '#e2e575',
    '#858707',
    '#6d6f00',
    '#525400',
  ],
  palette: [
    '#f0fefa',
    '#b9f4e4',
    '#8ce1ca',
    '#67c5ac',
    '#51ab93',
    '#3d8f79',
    '#2b715f',
    '#1b5143',
    '#0e2e26',
    '#030a08',
  ],
};

export const blueGreen: OcTheme = {
  primaryColor: '#025966',
  accentName: 'green',
  ...themeDefaults,
  gradientEndPalette: [
    '#ebf7ff',
    '#bce4ff',
    '#8ed0fa',
    '#2c8cc9',
    '#146da6',
    '#054d7b',
  ],
  gradientMiddlePalette: [
    '#ebfdff',
    '#b0f3fe',
    '#7be4f4',
    '#1999ac',
    '#0b7b8b',
    '#025966',
  ],
  gradientStartPalette: [
    '#f0fefa',
    '#b9f4e4',
    '#8ce1ca',
    '#3d8f79',
    '#2b715f',
    '#1b5143',
  ],
  palette: [
    '#ebfdff',
    '#b0f3fe',
    '#7be4f4',
    '#50cee1',
    '#2db3c7',
    '#1999ac',
    '#0b7b8b',
    '#025966',
    '#00333b',
    '#00090a',
  ],
};

export const blue: OcTheme = {
  primaryColor: '#054d7b',
  accentName: 'blueGreen',
  ...themeDefaults,
  gradientEndPalette: [
    '#f1f2ff',
    '#cacffc',
    '#a9b0f5',
    '#5962b7',
    '#414996',
    '#2b3271',
  ],
  gradientMiddlePalette: [
    '#ebf7ff',
    '#bce4ff',
    '#8ed0fa',
    '#2c8cc9',
    '#146da6',
    '#054d7b',
  ],
  gradientStartPalette: [
    '#ebfdff',
    '#b0f3fe',
    '#7be4f4',
    '#1999ac',
    '#0b7b8b',
    '#025966',
  ],
  palette: [
    '#ebf7ff',
    '#bce4ff',
    '#8ed0fa',
    '#68baef',
    '#47a4df',
    '#2c8cc9',
    '#146da6',
    '#054d7b',
    '#002a47',
    '#00060a',
  ],
};

export const blueViolet: OcTheme = {
  primaryColor: '#2b3271',
  accentName: 'blue',
  ...themeDefaults,
  gradientEndPalette: [
    '#fbf6fb',
    '#ead3e8',
    '#dab4d6',
    '#975590',
    '#7e3a77',
    '#5d2156',
  ],
  gradientMiddlePalette: [
    '#f1f2ff',
    '#cacffc',
    '#a9b0f5',
    '#5962b7',
    '#414996',
    '#2b3271',
  ],
  gradientStartPalette: [
    '#ebf7ff',
    '#bce4ff',
    '#8ed0fa',
    '#2c8cc9',
    '#146da6',
    '#054d7b',
  ],
  palette: [
    '#f1f2ff',
    '#cacffc',
    '#a9b0f5',
    '#8c95e8',
    '#757ed6',
    '#5962b7',
    '#414996',
    '#2b3271',
    '#191d4a',
    '#090b1f',
  ],
};

export const violet: OcTheme = {
  primaryColor: '#5d2156',
  accentName: 'blueViolet',
  ...themeDefaults,
  gradientEndPalette: [
    '#fff0f9',
    '#fcc8e7',
    '#f3a5d4',
    '#b5548e',
    '#943d71',
    '#702853',
  ],
  gradientMiddlePalette: [
    '#fbf6fb',
    '#ead3e8',
    '#dab4d6',
    '#975590',
    '#7e3a77',
    '#5d2156',
  ],
  gradientStartPalette: [
    '#f1f2ff',
    '#cacffc',
    '#a9b0f5',
    '#5962b7',
    '#414996',
    '#2b3271',
  ],
  palette: [
    '#fbf6fb',
    '#ead3e8',
    '#d8b1d4',
    '#c491bf',
    '#ae72a8',
    '#975590',
    '#7e3a77',
    '#5d2156',
    '#350e31',
    '#060106',
  ],
};

export const violetRed: OcTheme = {
  primaryColor: '#702853',
  accentName: 'violet',
  ...themeDefaults,
  gradientEndPalette: [
    '#ffefef',
    '#ffc6c6',
    '#ffa3a3',
    '#c15151',
    '#993838',
    '#6c2222',
  ],
  gradientMiddlePalette: [
    '#fff0f9',
    '#fcc8e7',
    '#f3a5d4',
    '#b5548e',
    '#943d71',
    '#702853',
  ],
  gradientStartPalette: [
    '#fbf6fb',
    '#ead3e8',
    '#dab4d6',
    '#975590',
    '#7e3a77',
    '#5d2156',
  ],
  palette: [
    '#fff0f9',
    '#fcc8e7',
    '#f3a5d4',
    '#e688c0',
    '#d36fab',
    '#b5548e',
    '#943d71',
    '#702853',
    '#491635',
    '#1f0816',
  ],
};

export const grey: OcTheme = {
  primaryColor: '#343c4c',
  accentName: 'grey',
  ...themeDefaults,
  gradientEndPalette: [
    '#d9dce1',
    '#bdc1c9',
    '#a1a6b1',
    '#4f5666',
    '#343c4c',
    '#1a212e',
  ],
  gradientMiddlePalette: [
    '#f6f7f8',
    '#d9dce1',
    '#bdc1c9',
    '#69717f',
    '#4f5666',
    '#343c4c',
  ],
  gradientStartPalette: [
    '#ffffff',
    '#f6f7f8',
    '#d9dce1',
    '#858b98',
    '#69717f',
    '#4f5666',
  ],
  palette: [
    '#f6f7f8',
    '#d9dce1',
    '#bdc1c9',
    '#a1a6b1',
    '#858b98',
    '#69717f',
    '#4f5666',
    '#343c4c',
    '#1a212e',
    '#05070a',
  ],
};

const themes: Record<OcThemeName, OcTheme> = {
  red,
  redOrange,
  orange,
  yellow,
  yellowGreen,
  green,
  blueGreen,
  blue,
  blueViolet,
  violet,
  violetRed,
  grey,
};

export default themes;
