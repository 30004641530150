import React from "react";
const AuthFooter = () => {
  const originHost = window.location.origin;

  const onClickFooterItems = (url: any) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const footerValues = [
    {
      url: "Privacy Notice",
      onClick: () => {
        onClickFooterItems(`${originHost}/assets/pdf/privacy-notice.pdf`);
      },
    },
    {
      url: "Terms of Use",
      onClick: () => {
        onClickFooterItems(`${originHost}/assets/pdf/terms-of-use.pdf`);
      },
    },
    {
      url: "Contact",
      onClick: () => {
        onClickFooterItems(`https://heidricksupport.zendesk.com/hc/en-us`);
      },
    },
  ];

  return (
    <div className="authFooter">
      <div className="footerLeftSide">
        <p className="footerText">powered By:</p>
        <img alt="navigatorLogo" src={`./../assets/img/navigatorLogo.svg`} />

      </div>

      <div className="footerRightSide">
        <div className="authFooterLinks">
          {footerValues.map((item, index) => {
            const lengthCheck = index + 1 === footerValues.length;

            return (
              <>
                <p
                  className={lengthCheck ? "authFooterPointer" : "authFooterSubtitle"}
                  onClick={item.onClick}
                >
                  {item.url}
                </p>
                {lengthCheck ? <div className="authFooterDividerLast"></div> : <div className="authFooterDivider"></div>}
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AuthFooter;
