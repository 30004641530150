import React, { createContext, ReactElement, ReactNode, useReducer } from 'react';
import Reducer from './reducer';
import { ContextType } from './types';
export function GlobalStore({ children }: { children: ReactNode }): ReactElement {
  const [globalState, dispatch] = useReducer(Reducer, initializeState());

  return <globalContext.Provider value={{ globalState, dispatch }}>{children}</globalContext.Provider>;
}

export const globalContext = createContext({} as ContextType);

export const initialState: any = {
  leaderData: {},
  clientData: {},
  lookUpData: []
};

function initializeState() {
  return initialState;
}
