import React from 'react';
import styles from '../empty.module.scss';

export const DefaultEmptyProfileImg = (): JSX.Element => {
  return (
    <svg
      className={'empty-profile-image-default'}
      fill="none"
      height="187"
      width="200"
      viewBox="0 0 200 187"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={styles.primaryColor10}
        d="M39.0718 180.839C21.8078 174.861 3.66074 165.325 0.510726 153.175C-2.63929 141.025 9.18787 126.359 24.8068 118.637C40.5358 111.029 59.9069 110.449 71.9662 105.257C83.9156 99.9498 88.5731 89.9309 99.9189 80.9985C111.265 72.066 129.299 64.2199 140.372 67.9517C151.555 71.798 155.648 87.2069 164.716 99.6426C173.785 112.078 187.68 121.625 194.904 134.657C202.237 147.804 202.768 164.422 189.061 169.219C175.355 174.016 147.3 166.877 128.36 168.625C109.569 170.288 99.7624 180.823 86.4541 184.955C73.2954 189.004 56.4854 186.734 39.0718 180.839Z"
        fill="currentColor"
      />
      <path
        className={styles.whiteColor}
        d="M159 112.96V131H48V112.96L48.4336 109.094C49.0117 105.944 50.168 102.937 51.9023 100.074C53.9258 95.7785 56.6719 92.0559 60.1406 88.906C64.4766 85.1834 69.9687 82.1767 76.6172 79.8859C84.1328 77.3087 93.0938 76.0201 103.5 76.0201C119.977 76.0201 132.984 79.17 142.523 85.4698C149.461 90.0515 154.375 96.0649 157.266 103.51C158.422 106.946 159 110.096 159 112.96ZM131.25 30.4899C131.25 22.7584 128.504 16.3154 123.012 11.1611C117.809 5.72036 111.305 3 103.5 3C95.9844 3 89.4805 5.72036 83.9883 11.1611C78.4961 16.3154 75.75 22.7584 75.75 30.4899C75.75 37.9351 78.4961 44.3781 83.9883 49.8188C89.4805 55.2595 95.9844 57.9799 103.5 57.9799C111.305 57.9799 117.809 55.2595 123.012 49.8188C128.504 44.3781 131.25 37.9351 131.25 30.4899Z"
        fill="currentColor"
      />
      <path
        className={styles.primaryColor30}
        d="M159 112.96V131H48V112.96L48.4336 109.094C49.0117 105.944 50.168 102.937 51.9023 100.074C53.9258 95.7785 56.6719 92.0559 60.1406 88.906C64.4766 85.1834 69.9687 82.1767 76.6172 79.8859C84.1328 77.3087 93.0938 76.0201 103.5 76.0201C119.977 76.0201 132.984 79.17 142.523 85.4698C149.461 90.0515 154.375 96.0649 157.266 103.51C158.422 106.946 159 110.096 159 112.96ZM131.25 30.4899C131.25 22.7584 128.504 16.3154 123.012 11.1611C117.809 5.72036 111.305 3 103.5 3C95.9844 3 89.4805 5.72036 83.9883 11.1611C78.4961 16.3154 75.75 22.7584 75.75 30.4899C75.75 37.9351 78.4961 44.3781 83.9883 49.8188C89.4805 55.2595 95.9844 57.9799 103.5 57.9799C111.305 57.9799 117.809 55.2595 123.012 49.8188C128.504 44.3781 131.25 37.9351 131.25 30.4899Z"
        stroke="currentColor"
        stroke-width="6"
      />
      <path
        className={styles.whiteColorFillPrimaryColor30Stroke}
        d="M38.3391 79.9439L38.4197 80.0328L38.5072 80.1149C40.5896 82.0714 43.1391 83.0714 46 83.0714C48.8641 83.0714 51.4042 82.0657 53.4032 80.031C55.4671 77.9302 56.5439 75.3331 56.5439 72.3929C56.5439 69.4874 55.4889 66.9326 53.4044 64.932C51.4384 62.8098 48.9043 61.7143 46 61.7143C43.0627 61.7143 40.4866 62.8312 38.4214 64.9332C36.4255 66.9647 35.4561 69.5243 35.4561 72.3929C35.4561 75.2597 36.4211 77.829 38.3391 79.9439ZM71.497 50.1225L71.5776 50.2113L71.6651 50.2935C72.6031 51.1748 73 52.1184 73 53.2857V91.5C73 92.6673 72.6031 93.6109 71.6651 94.4922L71.5776 94.5744L71.497 94.6632C70.6671 95.5783 69.7223 96 68.4561 96H23.5439C22.2706 96 21.221 95.5693 20.2453 94.5761C19.3994 93.7151 19 92.7467 19 91.5V53.2857C19 52.0391 19.3994 51.0706 20.2453 50.2096C21.221 49.2165 22.2706 48.7857 23.5439 48.7857H27.2281H30.2281V45.7857V38.1071C30.2281 35.1584 30.9196 32.527 32.278 30.1504C33.79 27.5909 35.7154 25.649 38.0579 24.2581L38.074 24.2486L38.0899 24.2389C40.5481 22.7376 43.1658 22 46 22C48.8332 22 51.3721 22.7365 53.688 24.2098L53.7494 24.2489L53.8126 24.2849C56.2513 25.6749 58.1385 27.5927 59.5119 30.089L59.5464 30.1517L59.5838 30.2126C61.0393 32.583 61.7719 35.1916 61.7719 38.1071V45.7857V48.7857H64.7719H68.4561C69.7223 48.7857 70.6671 49.2074 71.497 50.1225ZM31.7719 45.7857V48.7857H34.7719H57.2281H60.2281V45.7857V38.1071C60.2281 34.1433 58.8317 30.6934 56.0353 27.9683C53.3568 25.1208 49.9429 23.6786 46 23.6786C42.0335 23.6786 38.5735 25.1356 35.7898 27.969C33.0922 30.7147 31.7719 34.1672 31.7719 38.1071V45.7857Z"
        stroke-width="6"
      />
      <ellipse
        className={styles.accentColor20}
        cx="46"
        cy="72.5"
        fill="currentColor"
        rx="8"
        ry="8.5"
      />
    </svg>
  );
};
