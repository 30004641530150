import React from 'react';

export const CopilotIconGradient = (): JSX.Element => {
  return (
    <svg
      className={'copilot-icon-gradient'}
      fill="none"
      height="24"
      width="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.91 19.6018L16.6776 16.6306L12.7181 16.3214C10.4034 16.1395 8.70286 15.4155 7.92978 14.2825C6.65337 12.403 6.85309 9.91317 8.40557 8.36189L4.81208 4.80445C1.53094 8.0843 1.06331 13.2492 3.71428 17.1493C3.71428 17.1493 3.71486 17.1504 3.71543 17.1504C3.71543 17.151 3.71601 17.1516 3.71658 17.1521C4.89141 18.8744 7.33692 21.0097 12.3198 21.4015L19.2408 21.9425C18.0154 21.8421 17.0093 20.8663 16.91 19.6018Z"
        fill="url(#paint0_linear_18229_141025)"
      />
      <path
        d="M21.9839 19.187C21.9856 19.2054 21.9919 19.2226 21.993 19.241L21.4521 12.3214C21.0604 7.34004 18.9255 4.89525 17.2035 3.72076C17.203 3.72076 17.2024 3.72019 17.2018 3.71961C17.2018 3.71961 17.2007 3.71904 17.2007 3.71847C13.3014 1.0677 8.09027 1.52484 4.81161 4.80445L8.38759 8.3792C9.93916 6.82718 12.4553 6.65669 14.3344 7.93273C15.4667 8.70558 16.1906 10.4056 16.3724 12.7196L16.9139 19.65C17.024 21.0535 18.2498 22.1017 19.6522 21.9921C20.2826 21.9428 20.8407 21.668 21.2548 21.2531C21.6695 20.8389 21.9443 20.2806 21.9936 19.65C22.0062 19.4928 21.9988 19.3385 21.9833 19.187H21.9839Z"
        fill="url(#paint1_linear_18229_141025)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_18229_141025"
          x1="32.1385"
          y1="19.3374"
          x2="6.87229"
          y2="13.1576"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2C8CC9" />
          <stop offset="0.49" stopColor="#5962B7" />
          <stop offset="1" stopColor="#975590" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_18229_141025"
          x1="19.7526"
          y1="16.0888"
          x2="9.70858"
          y2="6.04773"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2C8CC9" />
          <stop offset="0.49" stopColor="#5962B7" />
          <stop offset="1" stopColor="#975590" />
        </linearGradient>
      </defs>
    </svg>
  );
};
